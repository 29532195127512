/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter v3.1.1
 */

const BriefcaseMedical = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path d="M21.75 6H18V3.75C18 2.50781 16.9922 1.5 15.75 1.5H8.25C7.00781 1.5 6 2.50781 6 3.75V6H2.25C1.00781 6 0 7.00781 0 8.25V21.75C0 22.9922 1.00781 24 2.25 24H21.75C22.9922 24 24 22.9922 24 21.75V8.25C24 7.00781 22.9922 6 21.75 6ZM9 4.5H15V6H9V4.5ZM16.5 16.125C16.5 16.3313 16.3312 16.5 16.125 16.5H13.5V19.125C13.5 19.3312 13.3313 19.5 13.125 19.5H10.875C10.6687 19.5 10.5 19.3312 10.5 19.125V16.5H7.875C7.66875 16.5 7.5 16.3313 7.5 16.125V13.875C7.5 13.6687 7.66875 13.5 7.875 13.5H10.5V10.875C10.5 10.6687 10.6687 10.5 10.875 10.5H13.125C13.3313 10.5 13.5 10.6687 13.5 10.875V13.5H16.125C16.3312 13.5 16.5 13.6687 16.5 13.875V16.125Z" />
  </svg>
);

export default BriefcaseMedical;
