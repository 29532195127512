/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter v3.1.1
 */

const AddressCard = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path d="M22 2.66498H2C0.895833 2.66498 0 3.56081 0 4.66498V19.3316C0 20.4358 0.895833 21.3316 2 21.3316H22C23.1042 21.3316 24 20.4358 24 19.3316V4.66498C24 3.56081 23.1042 2.66498 22 2.66498ZM7.33333 6.66498C8.80417 6.66498 10 7.86081 10 9.33165C10 10.8025 8.80417 11.9983 7.33333 11.9983C5.8625 11.9983 4.66667 10.8025 4.66667 9.33165C4.66667 7.86081 5.8625 6.66498 7.33333 6.66498ZM12 16.5316C12 16.9733 11.5833 17.3316 11.0667 17.3316H3.6C3.08333 17.3316 2.66667 16.9733 2.66667 16.5316V15.7316C2.66667 14.4066 3.92083 13.3316 5.46667 13.3316H5.675C6.1875 13.5441 6.74583 13.665 7.33333 13.665C7.92083 13.665 8.48333 13.5441 8.99167 13.3316H9.2C10.7458 13.3316 12 14.4066 12 15.7316V16.5316ZM21.3333 14.3316C21.3333 14.515 21.1833 14.665 21 14.665H15C14.8167 14.665 14.6667 14.515 14.6667 14.3316V13.665C14.6667 13.4816 14.8167 13.3316 15 13.3316H21C21.1833 13.3316 21.3333 13.4816 21.3333 13.665V14.3316ZM21.3333 11.665C21.3333 11.8483 21.1833 11.9983 21 11.9983H15C14.8167 11.9983 14.6667 11.8483 14.6667 11.665V10.9983C14.6667 10.815 14.8167 10.665 15 10.665H21C21.1833 10.665 21.3333 10.815 21.3333 10.9983V11.665ZM21.3333 8.99831C21.3333 9.18164 21.1833 9.33165 21 9.33165H15C14.8167 9.33165 14.6667 9.18164 14.6667 8.99831V8.33165C14.6667 8.14831 14.8167 7.99831 15 7.99831H21C21.1833 7.99831 21.3333 8.14831 21.3333 8.33165V8.99831Z" />
  </svg>
);

export default AddressCard;
