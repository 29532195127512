/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter v3.1.1
 */

const StarOutline = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path d="M22.0042 8.47917L15.9167 7.59167L13.1958 2.075C12.7083 1.09167 11.2958 1.07917 10.8042 2.075L8.08333 7.59167L1.99583 8.47917C0.904165 8.6375 0.466665 9.98334 1.25833 10.7542L5.6625 15.0458L4.62083 21.1083C4.43333 22.2042 5.5875 23.025 6.55416 22.5125L12 19.65L17.4458 22.5125C18.4125 23.0208 19.5667 22.2042 19.3792 21.1083L18.3375 15.0458L22.7417 10.7542C23.5333 9.98334 23.0958 8.6375 22.0042 8.47917ZM16.1917 14.3458L17.1792 20.1125L12 17.3917L6.82083 20.1125L7.80833 14.3458L3.61666 10.2625L9.40833 9.42084L12 4.17084L14.5917 9.42084L20.3833 10.2625L16.1917 14.3458Z" />
  </svg>
);

export default StarOutline;
