/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter v3.1.1
 */

const LevelUpRight = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <g>
      <path d="M17.6977 5.60937L12.8243 0.359228C12.3797 -0.119554 11.6208 -0.120257 11.1756 0.359228L6.30172 5.60937C5.63582 6.32656 6.14347 7.49988 7.12611 7.49988H10.1249V20.2499H6.18857C6.1147 20.2499 6.04155 20.2644 5.97331 20.2927C5.90506 20.321 5.84306 20.3624 5.79083 20.4146L3.16583 23.0396C2.81146 23.394 3.06243 23.9999 3.56357 23.9999H12.7499C13.3713 23.9999 13.8749 23.4962 13.8749 22.8749V7.49988H16.8733C17.852 7.49988 18.3664 6.32946 17.6977 5.60937Z" />
    </g>
    <defs>
      <clipPath>
        <rect width="24" height="24" />
      </clipPath>
    </defs>
  </svg>
);

export default LevelUpRight;
