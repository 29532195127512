/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter v3.1.1
 */

const CintasBandagesColor = () => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M6.36934 1.88015C5.98988 1.88015 5.63383 2.02787 5.36523 2.29593L2.29391 5.36966C2.02597 5.63754 1.87815 5.99426 1.87815 6.37255C1.87815 6.75136 2.02597 7.10674 2.29458 7.37473L16.6273 21.7044C16.8947 21.9718 17.2514 22.1195 17.6307 22.1195C18.0095 22.1195 18.3662 21.9718 18.6348 21.7038L21.7061 18.6293C21.974 18.3608 22.1219 18.0054 22.1219 17.6266C22.1207 17.249 21.974 16.8936 21.7068 16.6268L7.37276 2.29593C7.10416 2.02787 6.74751 1.88015 6.36934 1.88015ZM17.6307 23.9971C16.7505 23.9971 15.9227 23.6553 15.2999 23.0337L0.967121 8.70339C0.343748 8.08123 0.000602567 7.25337 7.90316e-07 6.37307C-0.000600986 5.49222 0.342478 4.66426 0.965249 4.04103L4.03717 0.968491C4.65995 0.345596 5.48847 0.00136948 6.36934 0.00136948C7.25014 0.00136948 8.07744 0.345081 8.70082 0.967804L23.0342 15.2981C23.657 15.9184 23.9995 16.7451 24 17.6254C24 18.5067 23.657 19.3347 23.0342 19.9581L19.9629 23.0325C19.3395 23.6546 18.5115 23.9971 17.6307 23.9971Z"
        fill="#75828B"
      />
      <path
        d="M1.63132 19.2991C0.711604 18.377 0.711003 16.885 1.63065 15.9635L15.9609 1.63076C16.8813 0.709852 18.3725 0.707968 19.2941 1.62956L22.3672 4.70209C23.2893 5.62231 23.2887 7.11551 22.3678 8.03643L8.03756 22.3698C7.11971 23.2906 5.62658 23.2914 4.70565 22.3698L1.63132 19.2991Z"
        fill="#008350"
      />
      <path
        d="M17.6283 1.8781C17.25 1.8781 16.8934 2.0265 16.6254 2.29456L2.29511 16.6273C1.74322 17.1805 1.74322 18.0811 2.29631 18.6341L5.36951 21.7054C5.63805 21.974 5.9935 22.1219 6.37234 22.1219C6.75113 22.1219 7.10598 21.974 7.3727 21.7068L21.7036 7.37273C21.9721 7.10481 22.12 6.74879 22.12 6.3693C22.12 5.98982 21.9721 5.63446 21.7036 5.3659L18.6305 2.29388C18.3625 2.02531 18.0064 1.8781 17.6283 1.8781ZM6.37234 24C5.49209 24 4.66417 23.6575 4.04141 23.0335L0.967664 19.9629C-0.316058 18.6772 -0.316058 16.5854 0.965792 15.2993L15.2961 0.967123C15.9194 0.343203 16.7474 1.90735e-06 17.6283 1.90735e-06C18.5078 1.90735e-06 19.3364 0.343203 19.9585 0.96524L23.0317 4.03709C23.6551 4.65998 23.9981 5.48777 23.9981 6.3693C23.9981 7.24957 23.6551 8.07801 23.0322 8.70078L8.70195 23.0342C8.08045 23.6575 7.25314 24 6.37234 24Z"
        fill="#75828B"
      />
      <path
        d="M11.379 14.5337C11.3316 14.2023 11.5619 13.8962 11.8933 13.8513C12.2235 13.8032 12.5278 14.0348 12.5734 14.3656C12.619 14.6951 12.3916 14.9982 12.0608 15.0481C11.7301 15.0937 11.4227 14.8639 11.379 14.5337Z"
        fill="white"
      />
      <path
        d="M8.89757 12.054C8.84955 11.7238 9.0799 11.4176 9.41127 11.3714C9.74213 11.324 10.0452 11.5549 10.092 11.8858C10.137 12.2159 9.90962 12.519 9.57951 12.5689C9.24746 12.6133 8.94128 12.3841 8.89757 12.054Z"
        fill="white"
      />
      <path
        d="M13.8582 12.0606C13.8101 11.731 14.0411 11.4267 14.3725 11.3812C14.7021 11.3337 15.0058 11.5641 15.052 11.8943C15.0982 12.2232 14.869 12.5287 14.5407 12.5743C14.2099 12.6199 13.905 12.3926 13.8582 12.0606Z"
        fill="white"
      />
      <path
        d="M11.38 9.57937C11.3344 9.248 11.5641 8.94303 11.8937 8.89563C12.2227 8.84881 12.5294 9.07736 12.575 9.41062C12.6218 9.73891 12.392 10.0457 12.0619 10.0913C11.7335 10.1368 11.428 9.90648 11.38 9.57937Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath>
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default CintasBandagesColor;
