/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter v3.1.1
 */

const CloudOfflineSolid = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path d="M22.5618 19.0263C23.4494 18.1553 24 16.942 24 15.6C24 13.2787 22.35 11.34 20.16 10.8975C20.3137 10.4962 20.4 10.0575 20.4 9.59998C20.4 7.61248 18.7875 5.99998 16.8 5.99998C16.0613 5.99998 15.3713 6.22498 14.8013 6.60748C13.7625 4.80748 11.8238 3.59998 9.6 3.59998C8.86468 3.59998 8.16036 3.73208 7.50946 3.97387L22.5618 19.0263Z" />
    <path d="M3.97391 7.5094C3.73211 8.1603 3.6 8.86464 3.6 9.59998C3.6 9.70123 3.60375 9.80248 3.6075 9.90373C1.5075 10.6425 0 12.645 0 15C0 17.9812 2.41875 20.4 5.4 20.4H16.8645L3.97391 7.5094Z" />
    <path d="M22 22C21.6092 22.3908 20.9757 22.3908 20.5849 22L3.17295 4.58805C2.78219 4.19729 2.78219 3.56374 3.17295 3.17298C3.56371 2.78222 4.19726 2.78222 4.58802 3.17298L22 20.5849C22.3907 20.9757 22.3907 21.6093 22 22Z" />
  </svg>
);

export default CloudOfflineSolid;
