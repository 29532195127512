/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter v3.1.1
 */

const Language = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path d="M0 7.20005C0 5.8763 1.07625 4.80005 2.4 4.80005H21.6C22.9238 4.80005 24 5.8763 24 7.20005V16.8C24 18.1238 22.9238 19.2 21.6 19.2H2.4C1.07625 19.2 0 18.1238 0 16.8V7.20005ZM12 7.20005V16.8H21.6V7.20005H12ZM6.68625 8.9963C6.56625 8.7263 6.29625 8.55005 6 8.55005C5.70375 8.55005 5.43375 8.7263 5.31375 8.9963L2.91375 14.3963C2.745 14.775 2.9175 15.2175 3.29625 15.3863C3.675 15.555 4.1175 15.3825 4.28625 15.0038L4.62 14.25H7.38L7.71375 15.0038C7.8825 15.3825 8.325 15.5513 8.70375 15.3863C9.0825 15.2213 9.25125 14.775 9.08625 14.3963L6.68625 8.9963ZM6 11.145L6.7125 12.75H5.2875L6 11.145ZM16.8 8.55005C17.2125 8.55005 17.55 8.88755 17.55 9.30005V9.45005H19.8C20.2125 9.45005 20.55 9.78755 20.55 10.2C20.55 10.6125 20.2125 10.95 19.8 10.95H19.725L19.665 11.1188C19.3313 12.0338 18.825 12.8663 18.18 13.5713C18.2138 13.5938 18.2475 13.6125 18.2812 13.6313L18.99 14.055C19.3463 14.2688 19.4587 14.7301 19.2488 15.0826C19.0387 15.4351 18.5737 15.5513 18.2213 15.3413L17.5125 14.9175C17.3438 14.8163 17.1825 14.7113 17.0212 14.5988C16.6238 14.88 16.2 15.1238 15.7463 15.3263L15.6112 15.3863C15.2325 15.555 14.79 15.3825 14.6213 15.0038C14.4525 14.625 14.625 14.1825 15.0038 14.0138L15.1388 13.9538C15.3788 13.845 15.6113 13.725 15.8325 13.5863L15.375 13.1288C15.0825 12.8363 15.0825 12.36 15.375 12.0675C15.6675 11.775 16.1437 11.775 16.4363 12.0675L16.9838 12.615L17.0025 12.6338C17.4675 12.1425 17.8463 11.5725 18.12 10.9463H14.1C13.6875 10.9463 13.35 10.6088 13.35 10.1963C13.35 9.7838 13.6875 9.4463 14.1 9.4463H16.05V9.2963C16.05 8.8838 16.3875 8.5463 16.8 8.5463V8.55005Z" />
  </svg>
);

export default Language;
