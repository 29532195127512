/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter v3.1.1
 */

const HandShake = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path d="M18.3002 9.58665H12.6004V11.698C12.6004 13.1948 11.3892 14.4126 9.90052 14.4126C8.41182 14.4126 7.20061 13.1948 7.20061 11.698V7.11337L4.76694 8.58376C4.04322 9.01734 3.60073 9.80533 3.60073 10.6499V12.4332L0.600835 14.175C0.0271044 14.5068 -0.171639 15.2458 0.1621 15.8226L3.162 21.0482C3.49199 21.6251 4.22696 21.8211 4.80069 21.4893L8.67806 19.2385H13.8004C15.1241 19.2385 16.2003 18.1564 16.2003 16.8255H16.8003C17.464 16.8255 18.0002 16.2864 18.0002 15.6191V13.2061H18.3002C18.799 13.2061 19.2002 12.8027 19.2002 12.3012V10.4915C19.2002 9.99007 18.799 9.58665 18.3002 9.58665ZM23.8388 8.17658L20.8389 2.95101C20.5089 2.37416 19.7739 2.17811 19.2002 2.50989L15.3228 4.76073H11.4905C11.0405 4.76073 10.6017 4.88892 10.2193 5.12644L8.96305 5.91443C8.61056 6.1331 8.40057 6.52144 8.40057 6.93616V11.698C8.40057 12.5312 9.0718 13.2061 9.90052 13.2061C10.7292 13.2061 11.4005 12.5312 11.4005 11.698V8.38017H18.3002C19.4589 8.38017 20.4002 9.3265 20.4002 10.4915V11.566L23.4001 9.82418C23.9738 9.48863 24.1688 8.75343 23.8388 8.17658Z" />
  </svg>
);

export default HandShake;
