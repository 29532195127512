/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter v3.1.1
 */

const CloudUpload = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path d="M16 12.8C16 12.6833 15.9625 12.5875 15.8875 12.5125L11.4875 8.11248C11.4125 8.03748 11.3167 7.99998 11.2 7.99998C11.0833 7.99998 10.9875 8.03748 10.9125 8.11248L6.525 12.5C6.44167 12.6 6.4 12.7 6.4 12.8C6.4 12.9166 6.4375 13.0125 6.5125 13.0875C6.5875 13.1625 6.68333 13.2 6.8 13.2H9.6V17.6C9.6 17.7083 9.63958 17.8021 9.71875 17.8812C9.79792 17.9604 9.89167 18 10 18H12.4C12.5083 18 12.6021 17.9604 12.6813 17.8812C12.7604 17.8021 12.8 17.7083 12.8 17.6V13.2H15.6C15.7083 13.2 15.8021 13.1604 15.8813 13.0812C15.9604 13.0021 16 12.9083 16 12.8ZM24 16.4C24 17.725 23.5312 18.8562 22.5938 19.7937C21.6562 20.7312 20.525 21.2 19.2 21.2H5.6C4.05833 21.2 2.73958 20.6521 1.64375 19.5562C0.547917 18.4604 0 17.1416 0 15.6C0 14.5166 0.291667 13.5166 0.875 12.6C1.45833 11.6833 2.24167 10.9958 3.225 10.5375C3.20833 10.2875 3.2 10.1083 3.2 9.99998C3.2 8.23331 3.825 6.72498 5.075 5.47498C6.325 4.22498 7.83333 3.59998 9.6 3.59998C10.9 3.59998 12.0896 3.96248 13.1687 4.68748C14.2479 5.41248 15.0333 6.37498 15.525 7.57498C16.1167 7.05831 16.8083 6.79998 17.6 6.79998C18.4833 6.79998 19.2375 7.11248 19.8625 7.73748C20.4875 8.36248 20.8 9.11664 20.8 9.99998C20.8 10.6333 20.6292 11.2083 20.2875 11.725C21.3708 11.9833 22.2604 12.5479 22.9563 13.4187C23.6521 14.2896 24 15.2833 24 16.4Z" />
  </svg>
);

export default CloudUpload;
