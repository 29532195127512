/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter v3.1.1
 */

const Envelope = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path d="M23.5453 8.94375C23.7281 8.79844 24 8.93437 24 9.16406V18.75C24 19.9922 22.9922 21 21.75 21H2.25C1.00781 21 0 19.9922 0 18.75V9.16875C0 8.93438 0.267187 8.80312 0.454687 8.94844C1.50469 9.76406 2.89688 10.8 7.67813 14.2734C8.66719 14.9953 10.3359 16.5141 12 16.5047C13.6734 16.5188 15.375 14.9672 16.3266 14.2734C21.1078 10.8 22.4953 9.75938 23.5453 8.94375ZM12 15C13.0875 15.0188 14.6531 13.6313 15.4406 13.0594C21.6609 8.54531 22.1344 8.15156 23.5687 7.02656C23.8406 6.81562 24 6.4875 24 6.14062V5.25C24 4.00781 22.9922 3 21.75 3H2.25C1.00781 3 0 4.00781 0 5.25V6.14062C0 6.4875 0.159375 6.81094 0.43125 7.02656C1.86563 8.14687 2.33906 8.54531 8.55938 13.0594C9.34688 13.6313 10.9125 15.0188 12 15Z" />
  </svg>
);

export default Envelope;
