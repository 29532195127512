/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter v3.1.1
 */

const CintasEyesColor = () => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.6843 14.8788C12.8004 14.9618 12.0187 14.3135 11.9357 13.4313C11.8654 12.6705 12.3379 11.9856 13.0361 11.7569C12.7099 11.6501 12.3622 11.5892 12.0007 11.5892C10.1806 11.5892 8.70526 13.0651 8.70526 14.8858C8.70526 16.7059 10.1806 18.1835 12.0007 18.1835C13.8225 18.1835 15.299 16.7059 15.299 14.8858C15.299 14.4708 15.2177 14.0762 15.0783 13.7111C14.9048 14.3339 14.364 14.815 13.6843 14.8788Z"
      fill="#00A0B2"
    />
    <path
      d="M4.88274 14.8852C4.88274 15.8997 5.09403 16.8818 5.51075 17.8035L5.65063 18.1151L5.37553 17.9138C3.72259 16.7106 2.67788 15.8824 2.18109 15.3826C2.0534 15.248 1.98199 15.071 1.98199 14.8852C1.98199 14.7065 2.04761 14.5353 2.16424 14.4065C2.66802 13.8969 3.7481 13.0402 5.37493 11.858L5.71097 11.6142L5.50262 11.9834C5.09111 12.9021 4.88274 13.8788 4.88274 14.8852ZM17.166 14.8852C17.166 17.7344 14.8485 20.0501 12.0006 20.0501C9.15261 20.0501 6.83517 17.7344 6.83517 14.8852C6.83517 12.0379 9.15261 9.72101 12.0006 9.72101C14.8485 9.72101 17.166 12.0379 17.166 14.8852ZM19.1271 14.8852C19.1271 13.876 18.9175 12.898 18.5043 11.9764L18.3633 11.6636L18.6355 11.858C20.2594 13.0379 21.3389 13.8928 21.8387 14.3966C21.9617 14.5364 22.0273 14.7053 22.0273 14.8852C22.0273 15.0686 21.9575 15.2439 21.8345 15.3768C21.3337 15.8824 20.2873 16.7117 18.6355 17.9138L18.3609 18.1133L18.5003 17.8035C18.9164 16.8778 19.1271 15.8968 19.1271 14.8852ZM23.452 13.5022C21.7951 11.692 17.3615 7.47898 12.0006 7.47898C6.63433 7.47898 2.17643 11.7199 0.507837 13.544C0.179916 13.9119 0 14.3867 0 14.8823C0 15.3722 0.17699 15.8434 0.500307 16.2115C2.16598 18.0397 6.6216 22.2909 12.0006 22.2909C17.3732 22.2909 21.8166 18.0564 23.4783 16.2352C23.8148 15.8638 24 15.3838 24 14.8823C24 14.367 23.805 13.8771 23.452 13.5022Z"
      fill="#75828B"
    />
    <path
      d="M20.4084 7.30548C20.4049 7.29618 20.3945 7.27122 20.387 7.25333C20.3701 7.20215 20.351 7.1593 20.3324 7.12213C20.2186 6.86736 20.0375 6.55503 19.9644 6.43257C19.9528 6.4083 19.9395 6.38625 19.9256 6.36295L17.4984 2.58231C17.3654 2.37525 17.1374 2.25043 16.8912 2.25043H16.8907C16.6452 2.25043 16.4165 2.37525 16.2836 2.58175L13.8199 6.40761C13.6713 6.64047 13.5123 6.95321 13.3922 7.19633C13.3823 7.2138 13.3748 7.23183 13.3673 7.25042C13.1449 7.79824 13.0323 8.38613 13.0323 8.995C13.0323 11.3572 14.7532 13.2794 16.867 13.2794C18.3405 13.2794 19.6563 12.3636 20.3109 10.8673C20.9337 9.31594 20.6394 7.96951 20.4084 7.30548Z"
      fill="#7B7D82"
    />
    <path
      d="M16.8914 2.79214C16.8292 2.79214 16.7718 2.82293 16.7394 2.87453L14.2756 6.70038C14.1462 6.903 14.0011 7.18676 13.8763 7.43695C13.6731 7.93665 13.5739 8.45488 13.5739 8.99523C13.5739 11.0585 15.051 12.7375 16.867 12.7375C18.1218 12.7375 19.2512 11.9378 19.8148 10.6511C20.3661 9.27732 20.1033 8.07644 19.8966 7.48341L19.8716 7.41948C19.8647 7.39798 19.8565 7.3794 19.8483 7.36386L19.8397 7.34348C19.7317 7.10493 19.5518 6.79788 19.4984 6.70912L19.4787 6.67139L17.0428 2.87453C17.0091 2.82293 16.9529 2.79214 16.8914 2.79214ZM16.867 13.8205C14.4543 13.8205 12.4909 11.6557 12.4909 8.99523C12.4909 8.31513 12.6169 7.65982 12.8653 7.04752C12.8786 7.01437 12.8925 6.98372 12.9076 6.95293C13.044 6.67888 13.2065 6.36267 13.3644 6.11539L15.828 2.28829C16.0626 1.92562 16.4595 1.70913 16.8908 1.70913C17.3231 1.70913 17.7207 1.92617 17.9534 2.28954L20.3812 6.07073C20.4027 6.10374 20.4218 6.138 20.4386 6.17225C20.5204 6.30983 20.7004 6.62147 20.8224 6.89024C20.8508 6.94877 20.8745 7.00619 20.8949 7.0643C20.9076 7.09744 20.9157 7.11825 20.9192 7.12879C21.1752 7.86356 21.5025 9.35332 20.8131 11.0695C20.0684 12.7724 18.5588 13.8205 16.867 13.8205Z"
      fill="white"
    />
    <path
      d="M19.7268 7.54291C19.7198 7.52557 19.7111 7.50866 19.703 7.48841C19.6996 7.46982 19.6903 7.45124 19.681 7.4321C19.5602 7.1593 19.3176 6.75766 19.3176 6.75766V6.75294L18.3826 5.29505L16.8917 2.9723L14.4291 6.79538C14.266 7.0496 14.0698 7.45526 14.0355 7.52141C13.8545 7.96952 13.7547 8.46859 13.7547 8.995C13.7547 10.9648 15.1464 12.5568 16.8673 12.5568C18.0808 12.5568 19.1325 11.7599 19.6409 10.5986C20.1934 9.22255 19.8962 8.03151 19.7268 7.54291Z"
      fill="#00A0B2"
    />
    <path
      d="M14.6953 7.81737C14.5496 8.18476 14.4759 8.58123 14.4759 8.995C14.4759 10.5609 15.549 11.8354 16.8671 11.8354C17.7579 11.8354 18.5676 11.2504 18.9796 10.3096C19.4254 9.19698 19.1886 8.20459 19.0534 7.80517C19.0447 7.78548 19.0348 7.76342 19.0266 7.73957C19.025 7.7368 19.0237 7.73263 19.0232 7.72972C19.0215 7.72681 19.0208 7.72459 19.0197 7.72279C18.9368 7.53417 18.7609 7.23183 18.6993 7.13087C18.6929 7.11922 18.6866 7.10826 18.6807 7.09661L16.8897 4.30731L15.0353 7.18648C14.9541 7.31366 14.8398 7.52433 14.6982 7.80988C14.6982 7.80988 14.6971 7.81335 14.6953 7.81737ZM16.8671 13.2788C14.7527 13.2788 13.0319 11.3578 13.0319 8.995C13.0319 8.38558 13.1451 7.79823 13.3674 7.24986C13.3743 7.23183 13.3824 7.2138 13.3923 7.19702C13.5125 6.95321 13.6709 6.64046 13.8201 6.40705L16.2838 2.58175C16.4167 2.37525 16.6454 2.25043 16.8908 2.25043H16.8914C17.137 2.25043 17.3656 2.3758 17.4986 2.58231L19.9251 6.36295C19.9396 6.38556 19.9529 6.40886 19.964 6.43257C20.0371 6.55503 20.2182 6.86736 20.332 7.12213C20.3511 7.15874 20.3702 7.20215 20.387 7.25333C20.3941 7.27191 20.4051 7.29563 20.4085 7.30548C20.6396 7.96951 20.9337 9.3154 20.311 10.8679C19.6559 12.3636 18.3401 13.2788 16.8671 13.2788Z"
      fill="#75828B"
    />
    <path
      d="M17.5515 11.035C17.4054 11.035 17.2678 10.9457 17.2138 10.8006C17.1441 10.616 17.2359 10.4106 17.4192 10.338C17.4477 10.3258 17.8911 10.1227 17.9984 9.36064C18.0263 9.16333 18.2068 9.02578 18.4064 9.05363C18.6038 9.08148 18.7408 9.26371 18.7129 9.46162C18.5429 10.6747 17.713 10.9985 17.6775 11.0118C17.6357 11.0275 17.5933 11.035 17.5515 11.035Z"
      fill="white"
    />
  </svg>
);

export default CintasEyesColor;
