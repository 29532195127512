/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter v3.1.1
 */

const Money = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path d="M13.2 13.2H12.6V9.89999C12.6 9.73424 12.4658 9.59999 12.3 9.59999H11.7908C11.613 9.59999 11.4394 9.65249 11.2916 9.75111L10.7168 10.1344C10.684 10.1562 10.6558 10.1843 10.6339 10.217C10.6119 10.2497 10.5967 10.2864 10.5889 10.325C10.5812 10.3637 10.5812 10.4034 10.5888 10.4421C10.5965 10.4807 10.6116 10.5175 10.6335 10.5502L10.9665 11.0494C10.9883 11.0821 11.0164 11.1103 11.0491 11.1322C11.0818 11.1542 11.1186 11.1694 11.1572 11.1772C11.1958 11.1849 11.2356 11.1849 11.2742 11.1773C11.3129 11.1697 11.3496 11.1545 11.3824 11.1326L11.4 11.121V13.2H10.8C10.6343 13.2 10.5 13.3342 10.5 13.5V14.1C10.5 14.2657 10.6343 14.4 10.8 14.4H13.2C13.3658 14.4 13.5 14.2657 13.5 14.1V13.5C13.5 13.3342 13.3658 13.2 13.2 13.2ZM22.8 4.79999H1.2C0.537375 4.79999 0 5.33736 0 5.99999V18C0 18.6626 0.537375 19.2 1.2 19.2H22.8C23.4626 19.2 24 18.6626 24 18V5.99999C24 5.33736 23.4626 4.79999 22.8 4.79999ZM1.8 17.4V15C3.12563 15 4.2 16.0744 4.2 17.4H1.8ZM1.8 8.99999V6.59999H4.2C4.2 7.92561 3.12563 8.99999 1.8 8.99999ZM12 16.2C10.0118 16.2 8.4 14.3194 8.4 12C8.4 9.68024 10.0118 7.79999 12 7.79999C13.9883 7.79999 15.6 9.68024 15.6 12C15.6 14.3201 13.9875 16.2 12 16.2ZM22.2 17.4H19.8C19.8 16.0744 20.8744 15 22.2 15V17.4ZM22.2 8.99999C20.8744 8.99999 19.8 7.92561 19.8 6.59999H22.2V8.99999Z" />
  </svg>
);

export default Money;
