/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter v3.1.1
 */

const FirstAidInverted = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <g>
      <path d="M20 13V11C20 10.7292 19.901 10.4948 19.7031 10.2969C19.5052 10.099 19.2708 10 19 10H14V5C14 4.72917 13.901 4.49479 13.7031 4.29688C13.5052 4.09896 13.2708 4 13 4H11C10.7292 4 10.4948 4.09896 10.2969 4.29688C10.099 4.49479 10 4.72917 10 5V10H5C4.72917 10 4.49479 10.099 4.29688 10.2969C4.09896 10.4948 4 10.7292 4 11V13C4 13.2708 4.09896 13.5052 4.29688 13.7031C4.49479 13.901 4.72917 14 5 14H10V19C10 19.2708 10.099 19.5052 10.2969 19.7031C10.4948 19.901 10.7292 20 11 20H13C13.2708 20 13.5052 19.901 13.7031 19.7031C13.901 19.5052 14 19.2708 14 19V14H19C19.2708 14 19.5052 13.901 19.7031 13.7031C19.901 13.5052 20 13.2708 20 13ZM24 4.5V19.5C24 20.7396 23.5599 21.7995 22.6797 22.6797C21.7995 23.5599 20.7396 24 19.5 24H4.5C3.26042 24 2.20052 23.5599 1.32031 22.6797C0.440104 21.7995 0 20.7396 0 19.5V4.5C0 3.26042 0.440104 2.20052 1.32031 1.32031C2.20052 0.440104 3.26042 0 4.5 0H19.5C20.7396 0 21.7995 0.440104 22.6797 1.32031C23.5599 2.20052 24 3.26042 24 4.5Z" />
    </g>
    <defs>
      <clipPath>
        <rect width="24" height="24" />
      </clipPath>
    </defs>
  </svg>
);

export default FirstAidInverted;
