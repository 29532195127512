/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter v3.1.1
 */

const Eye = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path d="M11.9997 3.60019C8.96979 3.60019 6.54358 4.98017 4.77737 6.62264C3.0224 8.25011 1.84867 10.2001 1.29368 11.5388C1.16993 11.835 1.16993 12.165 1.29368 12.4613C1.84867 13.8 3.0224 15.75 4.77737 17.3775C6.54358 19.0199 8.96979 20.3999 11.9997 20.3999C15.0297 20.3999 17.4559 19.0199 19.2221 17.3775C20.9771 15.7462 22.1508 13.8 22.7095 12.4613C22.8333 12.165 22.8333 11.835 22.7095 11.5388C22.1508 10.2001 20.9771 8.25011 19.2221 6.62264C17.4559 4.98017 15.0297 3.60019 11.9997 3.60019ZM6.59983 12C6.59983 10.5679 7.16875 9.19441 8.18143 8.18174C9.19411 7.16906 10.5676 6.60014 11.9997 6.60014C13.4319 6.60014 14.8054 7.16906 15.818 8.18174C16.8307 9.19441 17.3996 10.5679 17.3996 12C17.3996 13.4322 16.8307 14.8057 15.818 15.8184C14.8054 16.831 13.4319 17.4 11.9997 17.4C10.5676 17.4 9.19411 16.831 8.18143 15.8184C7.16875 14.8057 6.59983 13.4322 6.59983 12ZM11.9997 9.60009C11.9997 10.9238 10.9235 12 9.59978 12C9.33354 12 9.07854 11.955 8.83854 11.8763C8.6323 11.8088 8.3923 11.9363 8.3998 12.1538C8.41105 12.4125 8.44855 12.6713 8.5198 12.93C9.03354 14.85 11.0098 15.99 12.9297 15.4762C14.8497 14.9625 15.9897 12.9863 15.4759 11.0663C15.0597 9.51009 13.6835 8.46386 12.1535 8.40011C11.936 8.39261 11.8085 8.62885 11.876 8.83885C11.9547 9.07885 11.9997 9.33384 11.9997 9.60009Z" />
  </svg>
);

export default Eye;
