/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter v3.1.1
 */

const Temperature = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path d="M12 2C12.83 1.99993 13.6285 2.3174 14.2319 2.88731C14.8353 3.45722 15.1978 4.23637 15.245 5.065L15.25 5.25L15.251 13.202L15.331 13.271C16.2629 14.1018 16.8495 15.2518 16.975 16.494L16.994 16.746L17 17C17.0001 17.8238 16.7967 18.6349 16.4079 19.3612C16.019 20.0874 15.4567 20.7063 14.7709 21.1629C14.0852 21.6194 13.2973 21.8994 12.4772 21.9781C11.6572 22.0567 10.8304 21.9315 10.0704 21.6135C9.31039 21.2956 8.64073 20.7948 8.12093 20.1557C7.60113 19.5166 7.24731 18.7589 7.09092 17.9501C6.93453 17.1412 6.98042 16.3063 7.2245 15.5195C7.46858 14.7326 7.9033 14.0183 8.49 13.44L8.67 13.27L8.749 13.202L8.75 5.25C8.74983 4.4513 9.04378 3.68051 9.57573 3.08474C10.1077 2.48897 10.8404 2.10995 11.634 2.02L11.816 2.005L12 2ZM12 3.5C11.5607 3.50004 11.1374 3.66533 10.8143 3.96305C10.4912 4.26076 10.2919 4.66912 10.256 5.107L10.25 5.25V13.944L9.941 14.169C9.35063 14.5984 8.90932 15.2017 8.67884 15.8944C8.44835 16.5871 8.44024 17.3345 8.65563 18.0321C8.87102 18.7296 9.29913 19.3423 9.88004 19.7845C10.4609 20.2266 11.1656 20.476 11.8953 20.4979C12.625 20.5197 13.3432 20.3127 13.9495 19.9061C14.5558 19.4994 15.0197 18.9134 15.2764 18.2299C15.533 17.5465 15.5696 16.7999 15.3809 16.0947C15.1922 15.3895 14.7877 14.7609 14.224 14.297L14.059 14.17L13.752 13.945L13.75 5.25C13.75 4.78587 13.5656 4.34075 13.2374 4.01256C12.9092 3.68437 12.4641 3.5 12 3.5ZM12 8C12.1989 8 12.3897 8.07902 12.5303 8.21967C12.671 8.36032 12.75 8.55109 12.75 8.75V14.615C13.3201 14.7942 13.8071 15.1716 14.123 15.6788C14.4389 16.1861 14.5627 16.7896 14.4721 17.3803C14.3814 17.9709 14.0823 18.5096 13.6288 18.8988C13.1754 19.2879 12.5976 19.5019 12 19.5019C11.4024 19.5019 10.8246 19.2879 10.3712 18.8988C9.91772 18.5096 9.61859 17.9709 9.52794 17.3803C9.4373 16.7896 9.56112 16.1861 9.877 15.6788C10.1929 15.1716 10.6799 14.7942 11.25 14.615V8.75C11.25 8.55109 11.329 8.36032 11.4697 8.21967C11.6103 8.07902 11.8011 8 12 8Z" />
  </svg>
);

export default Temperature;
