/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter v3.1.1
 */

const ArrowAltRight = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path d="M16.216 13.875H2.0625C1.75186 13.875 1.5 13.6231 1.5 13.3125V10.6875C1.5 10.3769 1.75186 10.125 2.0625 10.125H16.216V7.96598C16.216 6.9637 17.4277 6.46177 18.1365 7.17047L22.1705 11.2045C22.6099 11.6438 22.6099 12.3562 22.1705 12.7955L18.1365 16.8295C17.4278 17.5382 16.216 17.0363 16.216 16.034V13.875Z" />
  </svg>
);

export default ArrowAltRight;
