/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter v3.1.1
 */

const SquareO = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <g>
      <path d="M19.0909 2.18182H4.90909C4.15909 2.18182 3.51705 2.44886 2.98295 2.98295C2.44886 3.51705 2.18182 4.15909 2.18182 4.90909V19.0909C2.18182 19.8409 2.44886 20.483 2.98295 21.017C3.51705 21.5511 4.15909 21.8182 4.90909 21.8182H19.0909C19.8409 21.8182 20.483 21.5511 21.017 21.017C21.5511 20.483 21.8182 19.8409 21.8182 19.0909V4.90909C21.8182 4.15909 21.5511 3.51705 21.017 2.98295C20.483 2.44886 19.8409 2.18182 19.0909 2.18182ZM24 4.90909V19.0909C24 20.4432 23.5199 21.5994 22.5597 22.5597C21.5994 23.5199 20.4432 24 19.0909 24H4.90909C3.55682 24 2.40057 23.5199 1.44034 22.5597C0.480114 21.5994 0 20.4432 0 19.0909V4.90909C0 3.55682 0.480114 2.40057 1.44034 1.44034C2.40057 0.480114 3.55682 0 4.90909 0H19.0909C20.4432 0 21.5994 0.480114 22.5597 1.44034C23.5199 2.40057 24 3.55682 24 4.90909Z" />
    </g>
    <defs>
      <clipPath>
        <rect width="24" height="24" />
      </clipPath>
    </defs>
  </svg>
);

export default SquareO;
