/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter v3.1.1
 */

const Compress = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path d="M21.9375 9H16.125C15.5016 9 15 8.49844 15 7.875V2.0625C15 1.75313 15.2531 1.5 15.5625 1.5H17.4375C17.7469 1.5 18 1.75313 18 2.0625V6H21.9375C22.2469 6 22.5 6.25313 22.5 6.5625V8.4375C22.5 8.74687 22.2469 9 21.9375 9ZM9 7.875V2.0625C9 1.75313 8.74687 1.5 8.4375 1.5H6.5625C6.25313 1.5 6 1.75313 6 2.0625V6H2.0625C1.75313 6 1.5 6.25313 1.5 6.5625V8.4375C1.5 8.74687 1.75313 9 2.0625 9H7.875C8.49844 9 9 8.49844 9 7.875ZM9 21.9375V16.125C9 15.5016 8.49844 15 7.875 15H2.0625C1.75313 15 1.5 15.2531 1.5 15.5625V17.4375C1.5 17.7469 1.75313 18 2.0625 18H6V21.9375C6 22.2469 6.25313 22.5 6.5625 22.5H8.4375C8.74687 22.5 9 22.2469 9 21.9375ZM18 21.9375V18H21.9375C22.2469 18 22.5 17.7469 22.5 17.4375V15.5625C22.5 15.2531 22.2469 15 21.9375 15H16.125C15.5016 15 15 15.5016 15 16.125V21.9375C15 22.2469 15.2531 22.5 15.5625 22.5H17.4375C17.7469 22.5 18 22.2469 18 21.9375Z" />
  </svg>
);

export default Compress;
