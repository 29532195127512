/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter v3.1.1
 */

const EyeSlash = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <g>
      <path d="M1.45491 2.59146C1.06492 2.28396 0.498677 2.35521 0.191183 2.74521C-0.116312 3.1352 -0.0450631 3.70144 0.34493 4.00893L22.5445 21.4086C22.9345 21.7161 23.5008 21.6449 23.8083 21.2549C24.1158 20.8649 24.0445 20.2986 23.6545 19.9912L19.7096 16.9012C21.1946 15.3787 22.1995 13.6725 22.7058 12.4613C22.8295 12.165 22.8295 11.835 22.7058 11.5388C22.147 10.2001 20.9733 8.25011 19.2183 6.62264C17.4559 4.98017 15.0297 3.60019 11.9997 3.60019C9.44227 3.60019 7.31231 4.58642 5.65109 5.88015L1.45491 2.59146ZM8.36604 8.00636C9.32227 7.13263 10.601 6.60014 11.9997 6.60014C14.9809 6.60014 17.3996 9.01885 17.3996 12C17.3996 12.9338 17.1634 13.8113 16.7471 14.5762L15.2997 13.4438C15.6147 12.72 15.6972 11.8913 15.4797 11.0701C15.0634 9.51384 13.6872 8.46761 12.1572 8.40386C11.9397 8.39636 11.8122 8.6326 11.8797 8.8426C11.9585 9.08259 12.0035 9.33759 12.0035 9.60384C12.0035 9.98633 11.9135 10.3463 11.756 10.6651L8.36979 8.01011L8.36604 8.00636ZM13.9872 17.0212C13.3722 17.265 12.701 17.3999 11.9997 17.3999C9.01853 17.3999 6.59982 14.9812 6.59982 12C6.59982 11.7413 6.61857 11.4901 6.65232 11.2426L3.11613 8.45636C2.26115 9.57009 1.64991 10.6801 1.29366 11.5388C1.16992 11.835 1.16992 12.165 1.29366 12.4613C1.8524 13.8 3.02613 15.75 4.7811 17.3774C6.54357 19.0199 8.96978 20.3999 11.9997 20.3999C13.7922 20.3999 15.3709 19.9162 16.7321 19.1812L13.9872 17.0212Z" />
    </g>
    <defs>
      <clipPath>
        <rect width="24" height="24" />
      </clipPath>
    </defs>
  </svg>
);

export default EyeSlash;
