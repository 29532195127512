/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter v3.1.1
 */

const CintasEyesGray = () => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.6843 14.6081C12.8004 14.6911 12.0187 14.0428 11.9356 13.1607C11.8654 12.3998 12.3378 11.7149 13.036 11.4863C12.7099 11.3794 12.3622 11.3185 12.0006 11.3185C10.1806 11.3185 8.7052 12.7944 8.7052 14.6151C8.7052 16.4352 10.1806 17.9127 12.0006 17.9127C13.8225 17.9127 15.299 16.4352 15.299 14.6151C15.299 14.2001 15.2177 13.8055 15.0783 13.4404C14.9048 14.0632 14.364 14.5443 13.6843 14.6081Z"
      fill="#75828B"
    />
    <path
      d="M20.4084 7.03475C20.4049 7.0256 20.3945 7.00063 20.3869 6.9826C20.3701 6.93157 20.351 6.88857 20.3324 6.85141C20.2186 6.59664 20.0375 6.28431 19.9644 6.16199C19.9528 6.13758 19.9395 6.11553 19.9256 6.09223L17.4984 2.31172C17.3654 2.10452 17.1374 1.9797 16.8912 1.9797H16.8907C16.6452 1.9797 16.4165 2.10452 16.2836 2.31117L13.8199 6.13702C13.6713 6.36974 13.5123 6.68248 13.3922 6.92574C13.3823 6.94308 13.3748 6.96111 13.3673 6.97969C13.1449 7.52765 13.0323 8.11555 13.0323 8.72432C13.0323 11.0865 14.7532 13.0087 16.867 13.0087C18.3405 13.0087 19.6563 12.0929 20.3109 10.5967C20.9337 9.04525 20.6394 7.69879 20.4084 7.03475Z"
      fill="#7B7D82"
    />
    <path
      d="M5.51075 17.5328L5.65063 17.8444L5.37547 17.643C3.72259 16.4399 2.67788 15.6116 2.18109 15.1119C2.0534 14.9773 1.98199 14.8003 1.98199 14.6146C1.98199 14.4358 2.04761 14.2646 2.16424 14.1358C2.66802 13.6262 3.7481 12.7695 5.37493 11.5873L5.71092 11.3435L5.50262 11.7127C5.09111 12.6314 4.88274 13.6082 4.88274 14.6146C4.88274 15.629 5.09403 16.611 5.51075 17.5328ZM23.452 13.2315C22.8806 12.6072 21.9788 11.6971 20.8227 10.7727C20.8192 10.7815 20.8165 10.79 20.8131 10.7988C20.5406 11.422 20.1647 11.9563 19.7159 12.3848C20.7465 13.1594 21.4596 13.7438 21.8387 14.1259C21.9617 14.2657 22.0273 14.4346 22.0273 14.6146C22.0273 14.7979 21.9575 14.9733 21.8345 15.1061C21.3337 15.6116 20.2873 16.441 18.6355 17.643L18.3609 17.8426L18.5003 17.5328C18.9164 16.607 19.1271 15.6262 19.1271 14.6146C19.1271 14.0587 19.0612 13.5129 18.9351 12.9803C18.3658 13.3178 17.7256 13.5118 17.0532 13.5427C17.1266 13.8887 17.166 14.247 17.166 14.6146C17.166 17.4637 14.8485 19.7794 12.0006 19.7794C9.15261 19.7794 6.83517 17.4637 6.83517 14.6146C6.83517 11.7672 9.15261 9.45031 12.0006 9.45031C12.1844 9.45031 12.3659 9.4604 12.5448 9.47919C12.5096 9.23318 12.4909 8.98127 12.4909 8.72453C12.4909 8.21127 12.5628 7.71224 12.7047 7.23516C12.4717 7.21935 12.2374 7.20825 12.0006 7.20825C6.63433 7.20825 2.17643 11.4492 0.507837 13.2733C0.179916 13.6412 0 14.116 0 14.6117C0 15.1015 0.177044 15.5727 0.500253 15.9408C2.16598 17.769 6.6216 22.0203 12.0006 22.0203C17.3732 22.0203 21.8165 17.7858 23.4783 15.9645C23.8148 15.5931 24 15.1131 24 14.6117C24 14.0963 23.805 13.6064 23.452 13.2315Z"
      fill="#75828B"
    />
    <path
      d="M19.9197 10.0893C18.233 8.88067 16.1354 7.76592 13.8012 7.36622C13.6488 7.80392 13.5739 8.25592 13.5739 8.72453C13.5739 9.0758 13.6177 9.41554 13.6977 9.73809C15.0268 10.2019 16.1095 11.1938 16.6938 12.4616C16.7512 12.465 16.8089 12.4668 16.867 12.4668C17.4906 12.4668 18.083 12.269 18.5906 11.9124C18.5622 11.8434 18.535 11.774 18.5044 11.7057L18.3633 11.3929L18.6356 11.5873C18.701 11.6348 18.764 11.681 18.8278 11.7275C19.2355 11.3811 19.5763 10.925 19.8148 10.3804C19.8542 10.2823 19.8883 10.1854 19.9197 10.0893Z"
      fill="#75828B"
    />
    <path
      d="M19.7267 7.27219C19.7198 7.25485 19.7111 7.23793 19.703 7.21768C19.6996 7.1991 19.6903 7.18051 19.681 7.16138C19.5602 6.88858 19.3176 6.48693 19.3176 6.48693V6.48236L18.3826 5.02433L16.8917 2.70172L14.429 6.52466C14.266 6.77887 14.0698 7.18454 14.0355 7.25069C13.8545 7.69879 13.7546 8.1979 13.7546 8.72432C13.7546 10.6941 15.1464 12.2861 16.8673 12.2861C18.0808 12.2861 19.1325 11.4893 19.6409 10.3279C20.1934 8.95185 19.8962 7.76092 19.7267 7.27219Z"
      fill="white"
    />
    <path
      d="M14.6953 7.54665C14.5496 7.91417 14.4759 8.31053 14.4759 8.72432C14.4759 10.2902 15.549 11.5647 16.8671 11.5647C17.7579 11.5647 18.5676 10.9797 18.9796 10.0389C19.4254 8.92628 19.1886 7.93387 19.0534 7.53458C19.0447 7.51475 19.0348 7.4927 19.0266 7.46899C19.025 7.46607 19.0237 7.46205 19.0232 7.45914C19.0215 7.45623 19.0208 7.45387 19.0197 7.45206C18.9368 7.26345 18.7609 6.96111 18.6993 6.86014C18.6929 6.84849 18.6866 6.83754 18.6807 6.82589L16.8897 4.03659L15.0353 6.9159C14.9541 7.04294 14.8398 7.2536 14.6982 7.53916C14.6982 7.53916 14.6971 7.54263 14.6953 7.54665ZM16.8671 13.0081C14.7527 13.0081 13.0319 11.0871 13.0319 8.72432C13.0319 8.11486 13.145 7.52765 13.3674 6.97914C13.3743 6.96111 13.3824 6.94308 13.3923 6.9263C13.5125 6.68248 13.6709 6.36974 13.8201 6.13633L16.2838 2.31117C16.4167 2.10452 16.6454 1.9797 16.8908 1.9797H16.8914C17.1369 1.9797 17.3656 2.10508 17.4986 2.31173L19.9251 6.09223C19.9396 6.11497 19.9529 6.13813 19.964 6.16199C20.0371 6.28431 20.2182 6.59664 20.332 6.85141C20.3511 6.88802 20.3702 6.93157 20.387 6.9826C20.3941 7.00119 20.4051 7.02491 20.4085 7.03475C20.6396 7.69879 20.9337 9.04471 20.311 10.5972C19.6557 12.0929 18.3401 13.0081 16.8671 13.0081Z"
      fill="#75828B"
    />
    <path
      d="M17.5515 10.7644C17.4054 10.7644 17.2678 10.675 17.2138 10.5299C17.1441 10.3453 17.2359 10.1399 17.4192 10.0673C17.4477 10.0551 17.8911 9.85202 17.9984 9.09C18.0263 8.89263 18.2068 8.75508 18.4064 8.78293C18.6038 8.81078 18.7408 8.99303 18.7129 9.19092C18.5429 10.404 17.7128 10.7278 17.6775 10.7412C17.6357 10.7568 17.5933 10.7644 17.5515 10.7644Z"
      fill="white"
    />
  </svg>
);

export default CintasEyesGray;
