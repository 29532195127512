/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter v3.1.1
 */

const CheckAltCircle = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <g>
      <path d="M23.625 12C23.625 18.4203 18.4203 23.625 12 23.625C5.57967 23.625 0.375 18.4203 0.375 12C0.375 5.57967 5.57967 0.375 12 0.375C18.4203 0.375 23.625 5.57967 23.625 12ZM10.6553 18.1553L19.2803 9.53034C19.5732 9.23747 19.5732 8.76258 19.2803 8.4697L18.2197 7.40906C17.9268 7.11614 17.4519 7.11614 17.159 7.40906L10.125 14.443L6.84098 11.159C6.54811 10.8661 6.07322 10.8661 5.7803 11.159L4.71966 12.2197C4.42678 12.5125 4.42678 12.9874 4.71966 13.2803L9.59466 18.1553C9.88758 18.4482 10.3624 18.4482 10.6553 18.1553Z" />
    </g>
    <defs>
      <clipPath>
        <rect width="24" height="24" />
      </clipPath>
    </defs>
  </svg>
);

export default CheckAltCircle;
