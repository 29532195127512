/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter v3.1.1
 */

const Truck = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path d="M21 6.83076L24 12.7385V17.1692H22.0976C22.353 17.6038 22.5 18.1079 22.5 18.6461C22.5 20.2775 21.1568 21.6 19.5 21.6C17.8432 21.6 16.5 20.2775 16.5 18.6461C16.5 18.1079 16.647 17.6038 16.9024 17.1692H8.59767C8.85309 17.6038 9 18.1079 9 18.6461C9 20.2775 7.65684 21.6 6 21.6C4.34316 21.6 3 20.2775 3 18.6461C3 18.1079 3.14691 17.6038 3.40233 17.1692H1.5L0 15.6923V3.87692C0 3.06461 0.675 2.39999 1.5 2.39999H15C15.825 2.39999 16.5 3.06461 16.5 3.87692V6.83076H21ZM16.5 8.30771V12.7385H21.8594L19.6094 8.30771H16.5Z" />
  </svg>
);

export default Truck;
