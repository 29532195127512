/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter v3.1.1
 */

const Camera = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path d="M9.12188 1.5H14.8781C15.8484 1.5 16.7062 2.11969 17.0109 3.03844L17.4984 4.5H21C22.6547 4.5 24 5.84531 24 7.5V19.5C24 21.1547 22.6547 22.5 21 22.5H3C1.34297 22.5 0 21.1547 0 19.5V7.5C0 5.84531 1.34297 4.5 3 4.5H6.50156L6.98906 3.03844C7.29375 2.11969 8.15156 1.5 9.12188 1.5ZM12 18C14.4844 18 16.5 15.9844 16.5 13.5C16.5 10.9734 14.4844 9 12 9C9.47344 9 7.5 10.9734 7.5 13.5C7.5 15.9844 9.47344 18 12 18Z" />
  </svg>
);

export default Camera;
