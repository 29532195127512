/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter v3.1.1
 */

const HourglassEnd = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path d="M19.875 3C20.4963 3 21 2.49633 21 1.875V1.125C21 0.503672 20.4963 0 19.875 0H4.125C3.50367 0 3 0.503672 3 1.125V1.875C3 2.49633 3.50367 3 4.125 3C4.125 7.26398 6.51638 10.8625 9.78947 12C6.51638 13.1375 4.125 16.736 4.125 21C3.50367 21 3 21.5037 3 22.125V22.875C3 23.4963 3.50367 24 4.125 24H19.875C20.4963 24 21 23.4963 21 22.875V22.125C21 21.5037 20.4963 21 19.875 21C19.875 16.736 17.4836 13.1375 14.2105 12C17.4836 10.8625 19.875 7.26398 19.875 3ZM12 9.75C9.29123 9.75 7.125 6.63197 7.125 3H16.875C16.875 6.65367 14.6948 9.75 12 9.75Z" />
  </svg>
);

export default HourglassEnd;
