/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter v3.1.1
 */

const MapPin = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path d="M10.5 14.8566V22.2014L11.5322 23.7492C11.7548 24.083 12.2456 24.083 12.4683 23.7492L13.5 22.2014V14.8566C13.013 14.9466 12.5128 15 12 15C11.4872 15 10.987 14.9466 10.5 14.8566ZM12 0C8.27203 0 5.25 3.02203 5.25 6.75C5.25 10.478 8.27203 13.5 12 13.5C15.728 13.5 18.75 10.478 18.75 6.75C18.75 3.02203 15.728 0 12 0ZM12 3.5625C10.2422 3.5625 8.8125 4.99219 8.8125 6.75C8.8125 7.06031 8.56031 7.3125 8.25 7.3125C7.93969 7.3125 7.6875 7.06031 7.6875 6.75C7.6875 4.37203 9.6225 2.4375 12 2.4375C12.3103 2.4375 12.5625 2.68969 12.5625 3C12.5625 3.31031 12.3103 3.5625 12 3.5625Z" />
  </svg>
);

export default MapPin;
