/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter v3.1.1
 */

const ClipboardCheck = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path d="M18.75 3H15C15 1.34531 13.6547 0 12 0C10.3453 0 9 1.34531 9 3H5.25C4.00781 3 3 4.00781 3 5.25V21.75C3 22.9922 4.00781 24 5.25 24H18.75C19.9922 24 21 22.9922 21 21.75V5.25C21 4.00781 19.9922 3 18.75 3ZM12 1.875C12.6234 1.875 13.125 2.37656 13.125 3C13.125 3.62344 12.6234 4.125 12 4.125C11.3766 4.125 10.875 3.62344 10.875 3C10.875 2.37656 11.3766 1.875 12 1.875ZM17.6813 12.7406L10.9781 19.3875C10.7578 19.6078 10.4016 19.6031 10.1812 19.3828L6.30937 15.4781C6.08906 15.2578 6.09375 14.9016 6.31406 14.6813L7.64531 13.3594C7.86562 13.1391 8.22187 13.1438 8.44219 13.3641L10.5984 15.5391L15.5672 10.6078C15.7875 10.3875 16.1438 10.3922 16.3641 10.6125L17.6859 11.9438C17.9062 12.1687 17.9016 12.5203 17.6813 12.7406Z" />
  </svg>
);

export default ClipboardCheck;
