/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter v3.1.1
 */

const LevelUpLeft = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <g>
      <path d="M6.30214 5.60937L11.1755 0.359228C11.6202 -0.119554 12.3791 -0.120257 12.8243 0.359228L17.6982 5.60937C18.3641 6.32656 17.8564 7.49988 16.8738 7.49988H13.8749V20.2499H17.8113C17.8852 20.2499 17.9583 20.2644 18.0266 20.2927C18.0948 20.321 18.1568 20.3624 18.209 20.4146L20.834 23.0396C21.1884 23.394 20.9375 23.9999 20.4363 23.9999H11.2499C10.6286 23.9999 10.1249 23.4962 10.1249 22.8749V7.49988H7.12653C6.14787 7.49988 5.63351 6.32946 6.30214 5.60937Z" />
    </g>
    <defs>
      <clipPath>
        <rect width="24" height="24" />
      </clipPath>
    </defs>
  </svg>
);

export default LevelUpLeft;
