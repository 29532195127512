/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter v3.1.1
 */

const Clock = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path d="M12 0C5.37097 0 0 5.37097 0 12C0 18.629 5.37097 24 12 24C18.629 24 24 18.629 24 12C24 5.37097 18.629 0 12 0ZM14.7629 16.9403L10.4952 13.8387C10.3452 13.7274 10.2581 13.5532 10.2581 13.3694V5.22581C10.2581 4.90645 10.5194 4.64516 10.8387 4.64516H13.1613C13.4806 4.64516 13.7419 4.90645 13.7419 5.22581V11.8887L16.8145 14.1242C17.0758 14.3129 17.129 14.6758 16.9403 14.9371L15.5758 16.8145C15.3871 17.071 15.0242 17.129 14.7629 16.9403Z" />
  </svg>
);

export default Clock;
