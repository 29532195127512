/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter v3.1.1
 */

const CintasEmergencyGray = () => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M12.0257 22.943C7.63882 22.943 4.07141 19.1736 4.07141 14.5415C4.07141 13.3429 4.31219 12.1778 4.78653 11.079C4.79504 11.0587 4.80485 11.0383 4.81473 11.0187C4.83637 10.9787 5.32116 10.0274 5.7528 9.40222L11.2089 1.51287C11.407 1.22822 11.7317 1.05768 12.0781 1.05768H12.0794C12.4265 1.05768 12.7519 1.22822 12.9487 1.51494L18.3221 9.31954C18.3365 9.34054 18.3503 9.36218 18.3634 9.38382C18.4926 9.58197 18.9564 10.3095 19.2188 10.8723C19.2307 10.8986 19.2425 10.9261 19.2523 10.9531C19.2661 10.9819 19.2792 11.0141 19.293 11.0482C19.314 11.0967 19.3324 11.1407 19.3474 11.182C19.7502 12.25 20.5243 15.0492 19.1441 18.2643C19.1382 18.2768 19.1329 18.2893 19.127 18.3004C17.7671 21.1651 15.0466 22.943 12.0257 22.943Z"
        fill="white"
      />
      <path
        d="M12.0782 0.00062561C11.3842 0.00062561 10.7347 0.341082 10.3404 0.911829L4.88369 8.80112C4.41072 9.48537 3.89513 10.4969 3.87341 10.5396C3.85315 10.5796 3.83346 10.6196 3.81636 10.6589C3.28496 11.8916 3.01404 13.1971 3.01404 14.5413C3.01404 19.7566 7.05708 24.0004 12.0257 24.0004C15.458 24.0004 18.5453 21.9897 20.0816 18.7542C20.0934 18.7306 20.104 18.7056 20.1138 18.6807C21.6377 15.1349 20.7947 12.0353 20.3427 10.8295C20.3177 10.7587 20.2922 10.6983 20.2692 10.6445C20.2502 10.5987 20.2344 10.5599 20.2233 10.5343C20.2089 10.4976 20.1931 10.4622 20.1767 10.4261C19.894 9.81797 19.4125 9.0616 19.2603 8.82535C19.2387 8.78994 19.2157 8.75452 19.1927 8.71975L17.1151 5.70409L13.8186 0.91515C13.425 0.343781 12.775 0.00145531 12.0802 0.00062561H12.0782ZM12.0782 2.1137L15.3747 6.90262L17.4523 9.9183V9.92098C17.4523 9.92098 17.9968 10.7501 18.2612 11.3182C18.2743 11.3563 18.294 11.393 18.3077 11.4331C18.3222 11.4718 18.3431 11.5098 18.3537 11.5433C18.7381 12.5535 19.3915 15.009 18.172 17.8469C17.0364 20.2414 14.7141 21.8867 12.0257 21.8867C8.21495 21.8867 5.12771 18.5987 5.12771 14.5413C5.12771 13.4523 5.35609 12.423 5.75683 11.498C5.82507 11.3616 6.25874 10.5284 6.62148 10.003L12.0782 2.1137Z"
        fill="#75828B"
      />
      <path
        d="M11.41 19.7876C11.3011 19.7876 11.1902 19.7698 11.0807 19.7331C7.95866 18.6756 7.13544 15.6487 7.10196 15.5202C6.95893 14.9718 7.28828 14.4102 7.83735 14.2672C8.3838 14.1242 8.94333 14.4502 9.08905 14.996C9.11588 15.0931 9.69192 17.0933 11.7393 17.7867C12.2772 17.9684 12.5652 18.5516 12.3828 19.0895C12.2379 19.5173 11.8383 19.7876 11.41 19.7876Z"
        fill="#75828B"
      />
    </g>
    <defs>
      <clipPath>
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default CintasEmergencyGray;
