/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter v3.1.1
 */

const CintasProductivityGray = () => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M9.82787 21.7047C9.82527 21.6974 9.8216 21.6906 9.81846 21.6833C9.81689 21.6753 9.81375 21.6686 9.81012 21.6613C9.76305 21.5536 9.66787 21.3951 9.66787 21.3951V21.393L9.3008 20.8189L8.71571 19.9044L7.74523 21.4061C7.6804 21.5066 7.60249 21.666 7.5894 21.6911C7.51775 21.8673 7.47853 22.0639 7.47803 22.2715C7.47647 23.0454 8.02286 23.6734 8.69895 23.675C9.17743 23.6755 9.59104 23.3628 9.79183 22.9068C10.0109 22.3656 9.89427 21.8966 9.82787 21.7047Z"
        fill="#75828B"
      />
      <path
        d="M7.88601 21.8254C7.83114 21.9645 7.8029 22.114 7.80236 22.2714C7.80182 22.8648 8.20449 23.3491 8.70018 23.3501C9.03376 23.3507 9.33807 23.1305 9.49494 22.7759C9.66435 22.3556 9.57599 21.9776 9.52475 21.8232C9.52058 21.8128 9.51636 21.8029 9.51274 21.7924C9.51274 21.7924 9.51274 21.7914 9.5117 21.7909C9.48032 21.7192 9.41285 21.6021 9.38932 21.5629C9.38672 21.5581 9.38358 21.5535 9.38148 21.5488L8.71479 20.5057L8.01782 21.5828C7.97124 21.6549 7.90852 21.7804 7.88759 21.8222C7.88759 21.8222 7.88709 21.8238 7.88601 21.8254ZM8.69857 24.0001C7.84419 23.9985 7.15031 23.2231 7.15241 22.2709C7.15295 22.0252 7.19894 21.7888 7.28838 21.5686C7.29152 21.5603 7.29568 21.5514 7.30039 21.5435C7.33334 21.4767 7.40498 21.3323 7.47191 21.2304L8.4429 19.7276C8.50356 19.635 8.60603 19.5785 8.71691 19.5785C8.82777 19.5801 8.93075 19.636 8.99037 19.7292L9.94149 21.2188C9.94777 21.2277 9.95405 21.2376 9.95876 21.2481C9.98802 21.2983 10.0602 21.4212 10.1046 21.5232C10.1109 21.5357 10.1182 21.553 10.1256 21.5723L10.1292 21.5817C10.2275 21.8656 10.3447 22.4063 10.0926 23.0286C9.82803 23.6325 9.29521 24.0016 8.69857 24.0001Z"
        fill="#75828B"
      />
      <path
        d="M8.07327 16.5961C8.93604 16.4413 9.77943 16.6291 10.4696 17.0578L21.0854 6.94832L15.2771 0.856096L2.91498 12.6211C4.21959 13.9895 5.84838 15.6989 7.04944 16.9585C7.36163 16.788 7.70409 16.6625 8.07327 16.5961Z"
        fill="white"
      />
      <path
        d="M7.922 15.754C8.74294 15.6109 9.57015 15.7065 10.3184 16.0207L19.8758 6.91777L15.2477 2.06492L4.12481 12.6508L7.25327 15.9324C7.47128 15.8545 7.69458 15.7954 7.922 15.754ZM10.6165 17.9011C10.4146 17.9366 10.2008 17.899 10.0172 17.785C9.48286 17.4529 8.85067 17.3285 8.23627 17.4362C7.95912 17.4864 7.70086 17.5769 7.45872 17.7091C7.11833 17.8938 6.69795 17.8284 6.43076 17.5487L2.29573 13.2113C2.13886 13.0476 2.05364 12.827 2.05888 12.6006C2.06462 12.3737 2.16033 12.1582 2.32504 12.0019L14.6871 0.236359C15.0291 -0.0894203 15.5698 -0.0757866 15.8961 0.265636L21.7044 6.35786C21.8612 6.52149 21.9459 6.7421 21.9411 6.96851C21.9354 7.19546 21.8392 7.41088 21.6751 7.56722L11.0588 17.6779C10.9333 17.7975 10.7791 17.8724 10.6165 17.9011Z"
        fill="#75828B"
      />
    </g>
    <defs>
      <clipPath>
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default CintasProductivityGray;
