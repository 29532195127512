/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter v3.1.1
 */

const ExclamationTriangle = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <g>
      <path d="M23.7298 19.6399C24.4989 20.9682 23.5335 22.6286 21.9974 22.6286H2.00217C0.463133 22.6286 -0.497784 20.9656 0.269799 19.6399L10.2675 2.36727C11.037 1.03832 12.9642 1.04073 13.7323 2.36727L23.7298 19.6399ZM11.9999 16.0688C10.9414 16.0688 10.0833 16.9238 10.0833 17.9786C10.0833 19.0334 10.9414 19.8884 11.9999 19.8884C13.0585 19.8884 13.9166 19.0334 13.9166 17.9786C13.9166 16.9238 13.0585 16.0688 11.9999 16.0688ZM10.1802 9.20397L10.4893 14.8504C10.5038 15.1146 10.723 15.3215 10.9885 15.3215H13.0113C13.2768 15.3215 13.4961 15.1146 13.5105 14.8504L13.8196 9.20397C13.8353 8.91858 13.6072 8.6786 13.3204 8.6786H10.6794C10.3926 8.6786 10.1646 8.91858 10.1802 9.20397Z" />
    </g>
    <defs>
      <clipPath>
        <rect width="24" height="24" />
      </clipPath>
    </defs>
  </svg>
);

export default ExclamationTriangle;
