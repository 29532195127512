/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter v3.1.1
 */

const PersonSlipping = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <g>
      <rect x="12" y="22" width="12" height="2" rx="1" />
      <path d="M2.14185 4.70837C1.60535 4.4471 1.19462 3.98341 1 3.4193C0.805383 2.85519 0.842829 2.23687 1.1041 1.70037C1.36537 1.16387 1.82906 0.753134 2.39317 0.558516C2.95728 0.363899 3.5756 0.401344 4.1121 0.662615C4.6486 0.923885 5.05934 1.38758 5.25395 1.95169C5.44857 2.5158 5.41113 3.13411 5.14986 3.67062C4.88858 4.20712 4.42489 4.61785 3.86078 4.81247C3.29667 5.00709 2.67836 4.96964 2.14185 4.70837ZM14.183 15.7588L12.0663 13.4246C12.2359 13.3977 12.4031 13.354 12.5724 13.3061L15.2789 12.5021L16.5069 13.8561C16.7105 14.0804 16.8684 14.345 16.9678 14.6333L18.3018 18.4998C18.572 19.2831 18.1554 20.1386 17.3721 20.4089C16.5887 20.6791 15.7332 20.2625 15.463 19.4792L14.183 15.7588Z" />
      <path d="M9.70266 3.31099C7.61962 3.18813 5.51242 4.48373 4.59707 6.36332L1.68362 12.6235C1.34326 13.3788 1.67961 14.267 2.43489 14.6073C3.19017 14.9477 4.07836 14.6113 4.41872 13.8561L6.49999 9.00004C6.49999 9.00004 6.48664 8.99829 6.99331 9.24917C7.49999 9.50004 9.32256 11.2646 9.32256 11.2646C10.0969 11.9337 11.158 12.1637 12.1402 11.8704L16.8405 10.4732L21.0048 11.3541C21.8179 11.5259 22.6113 11.0103 22.783 10.1972C22.9548 9.38406 22.435 8.58866 21.6261 8.41893L17.2726 7.49802C16.9029 7.42222 16.5257 7.43665 16.1662 7.54314L12.8746 8.52097L10.5103 6.36332L11.6766 6.43079C12.2178 6.46496 12.7465 6.3001 13.177 5.97274L14.2876 5.11626C14.9448 4.61253 15.0691 3.67203 14.5654 3.01483C14.0616 2.35763 13.1211 2.2333 12.4639 2.73703L11.5738 3.41929L9.70266 3.31099Z" />
    </g>
    <defs>
      <clipPath>
        <rect width="24" height="24" />
      </clipPath>
    </defs>
  </svg>
);

export default PersonSlipping;
