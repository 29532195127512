/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter v3.1.1
 */

const ChartLine = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path d="M3 18.75C3 19.1625 3.33562 19.5 3.75 19.5H22.5C23.3297 19.5 24 20.1703 24 21C24 21.8297 23.3297 22.5 22.5 22.5H3.75C1.67906 22.5 0 20.8219 0 18.75V3C0 2.17172 0.671719 1.5 1.5 1.5C2.32828 1.5 3 2.17172 3 3V18.75ZM16.0594 13.0594C15.4734 13.6453 14.5266 13.6453 13.9406 13.0594L11.25 10.3734L7.05938 14.5594C6.47344 15.1453 5.52656 15.1453 4.94063 14.5594C4.35375 13.9734 4.35375 13.0266 4.94063 12.4406L10.1906 7.19062C10.7766 6.60469 11.7234 6.60469 12.3094 7.19062L15 9.87656L19.9406 4.94063C20.5266 4.35375 21.4734 4.35375 22.0594 4.94063C22.6453 5.52656 22.6453 6.47344 22.0594 7.05938L16.0594 13.0594Z" />
  </svg>
);

export default ChartLine;
