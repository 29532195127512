/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter v3.1.1
 */

const Bell = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path d="M12 24C13.6556 24 14.9986 22.657 14.9986 21H9.00141C9.00141 22.657 10.3444 24 12 24ZM22.0964 16.9823C21.1908 16.0092 19.4963 14.5453 19.4963 9.75C19.4963 6.10781 16.9425 3.19219 13.4991 2.47688V1.5C13.4991 0.671719 12.8278 0 12 0C11.1722 0 10.5009 0.671719 10.5009 1.5V2.47688C7.0575 3.19219 4.50375 6.10781 4.50375 9.75C4.50375 14.5453 2.80922 16.0092 1.9036 16.9823C1.62235 17.2847 1.49766 17.6461 1.5 18C1.50516 18.7687 2.10844 19.5 3.00469 19.5H20.9953C21.8916 19.5 22.4953 18.7687 22.5 18C22.5023 17.6461 22.3777 17.2842 22.0964 16.9823Z" />
  </svg>
);

export default Bell;
