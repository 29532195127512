/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter v3.1.1
 */

const FilterAlt = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path d="M0.180608 2.57203C0.33255 2.25124 0.572454 1.98019 0.872411 1.79041C1.17237 1.60063 1.52005 1.49992 1.875 1.5H22.125C22.8516 1.5 23.5078 1.91719 23.8172 2.57203C24.1312 3.22688 24.0328 4.00172 23.5359 4.56234L15 15.0422V21C15 21.5672 14.6812 22.0875 14.1703 22.3406C13.6641 22.5938 13.0547 22.5422 12.6 22.2L9.6 19.95C9.22031 19.6688 9 19.2234 9 18.75V15.0422L0.423842 4.56234C0.199074 4.28763 0.0568998 3.95476 0.0138502 3.60243C-0.0291994 3.2501 0.0286437 2.89278 0.180655 2.57203H0.180608Z" />
  </svg>
);

export default FilterAlt;
