/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter v3.1.1
 */

const CalendarDays = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path d="M7.50516 0C8.33349 0 9.0027 0.670312 9.0027 1.5V3H14.9928V1.5C14.9928 0.670312 15.6621 0 16.4904 0C17.3187 0 17.9879 0.670312 17.9879 1.5V3H20.2342C21.4744 3 22.4805 4.00781 22.4805 5.25V7.5H1.51501V5.25C1.51501 4.00781 2.52117 3 3.76132 3H6.00763V1.5C6.00763 0.670312 6.67684 0 7.50516 0ZM1.51501 9H22.4805V21.75C22.4805 22.9922 21.4744 24 20.2342 24H3.76132C2.52117 24 1.51501 22.9922 1.51501 21.75V9ZM4.51009 12.75V14.25C4.51009 14.6625 4.84703 15 5.25886 15H6.75639C7.16822 15 7.50516 14.6625 7.50516 14.25V12.75C7.50516 12.3375 7.16822 12 6.75639 12H5.25886C4.84703 12 4.51009 12.3375 4.51009 12.75ZM10.5002 12.75V14.25C10.5002 14.6625 10.8372 15 11.249 15H12.7465C13.1584 15 13.4953 14.6625 13.4953 14.25V12.75C13.4953 12.3375 13.1584 12 12.7465 12H11.249C10.8372 12 10.5002 12.3375 10.5002 12.75ZM17.2392 12C16.8273 12 16.4904 12.3375 16.4904 12.75V14.25C16.4904 14.6625 16.8273 15 17.2392 15H18.7367C19.1485 15 19.4855 14.6625 19.4855 14.25V12.75C19.4855 12.3375 19.1485 12 18.7367 12H17.2392ZM4.51009 18.75V20.25C4.51009 20.6625 4.84703 21 5.25886 21H6.75639C7.16822 21 7.50516 20.6625 7.50516 20.25V18.75C7.50516 18.3375 7.16822 18 6.75639 18H5.25886C4.84703 18 4.51009 18.3375 4.51009 18.75ZM11.249 18C10.8372 18 10.5002 18.3375 10.5002 18.75V20.25C10.5002 20.6625 10.8372 21 11.249 21H12.7465C13.1584 21 13.4953 20.6625 13.4953 20.25V18.75C13.4953 18.3375 13.1584 18 12.7465 18H11.249ZM16.4904 18.75V20.25C16.4904 20.6625 16.8273 21 17.2392 21H18.7367C19.1485 21 19.4855 20.6625 19.4855 20.25V18.75C19.4855 18.3375 19.1485 18 18.7367 18H17.2392C16.8273 18 16.4904 18.3375 16.4904 18.75Z" />
  </svg>
);

export default CalendarDays;
