/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter v3.1.1
 */

const Building = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path d="M21.9375 22.5H21V1.125C21 0.503672 20.4963 0 19.875 0H4.125C3.50367 0 3 0.503672 3 1.125C3 1.74633 3 22.5 3 22.5H2.0625C1.75186 22.5 1.5 22.7519 1.5 23.0625V24H22.5V23.0625C22.5 22.7519 22.2481 22.5 21.9375 22.5ZM7.5 3.5625C7.5 3.25186 7.75186 3 8.0625 3H9.9375C10.2481 3 10.5 3.25186 10.5 3.5625V5.4375C10.5 5.74814 10.2481 6 9.9375 6H8.0625C7.75186 6 7.5 5.74814 7.5 5.4375V3.5625ZM7.5 8.0625C7.5 7.75186 7.75186 7.5 8.0625 7.5H9.9375C10.2481 7.5 10.5 7.75186 10.5 8.0625V9.9375C10.5 10.2481 10.2481 10.5 9.9375 10.5H8.0625C7.75186 10.5 7.5 10.2481 7.5 9.9375V8.0625ZM9.9375 15H8.0625C7.75186 15 7.5 14.7481 7.5 14.4375V12.5625C7.5 12.2519 7.75186 12 8.0625 12H9.9375C10.2481 12 10.5 12.2519 10.5 12.5625V14.4375C10.5 14.7481 10.2481 15 9.9375 15ZM13.5 22.5H10.5V18.5625C10.5 18.2519 10.7519 18 11.0625 18H12.9375C13.2481 18 13.5 18.2519 13.5 18.5625V22.5ZM16.5 14.4375C16.5 14.7481 16.2481 15 15.9375 15H14.0625C13.7519 15 13.5 14.7481 13.5 14.4375V12.5625C13.5 12.2519 13.7519 12 14.0625 12H15.9375C16.2481 12 16.5 12.2519 16.5 12.5625V14.4375ZM16.5 9.9375C16.5 10.2481 16.2481 10.5 15.9375 10.5H14.0625C13.7519 10.5 13.5 10.2481 13.5 9.9375V8.0625C13.5 7.75186 13.7519 7.5 14.0625 7.5H15.9375C16.2481 7.5 16.5 7.75186 16.5 8.0625V9.9375ZM16.5 5.4375C16.5 5.74814 16.2481 6 15.9375 6H14.0625C13.7519 6 13.5 5.74814 13.5 5.4375V3.5625C13.5 3.25186 13.7519 3 14.0625 3H15.9375C16.2481 3 16.5 3.25186 16.5 3.5625V5.4375Z" />
  </svg>
);

export default Building;
