/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter v3.1.1
 */

const AngleDoubleRight = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path d="M12.0139 12.7969L5.63891 19.1719C5.19828 19.6125 4.48578 19.6125 4.04984 19.1719L2.99047 18.1125C2.54984 17.6719 2.54984 16.9594 2.99047 16.5234L7.50922 12.0047L2.99047 7.48593C2.54984 7.04531 2.54984 6.33281 2.99047 5.89687L4.04516 4.82812C4.48578 4.38749 5.19828 4.38749 5.63422 4.82812L12.0092 11.2031C12.4545 11.6438 12.4545 12.3563 12.0139 12.7969ZM21.0139 11.2031L14.6389 4.82812C14.1983 4.38749 13.4858 4.38749 13.0498 4.82812L11.9905 5.8875C11.5498 6.32812 11.5498 7.04062 11.9905 7.47656L16.5092 11.9953L11.9905 16.5141C11.5498 16.9547 11.5498 17.6672 11.9905 18.1031L13.0498 19.1625C13.4905 19.6031 14.203 19.6031 14.6389 19.1625L21.0139 12.7875C21.4545 12.3563 21.4545 11.6438 21.0139 11.2031Z" />
  </svg>
);

export default AngleDoubleRight;
