/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter v3.1.1
 */

const ArrowRight = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path d="M10.438 20.8641L11.4731 21.9047C11.9114 22.3453 12.6201 22.3453 13.0538 21.9047L22.1181 12.7969C22.5564 12.3563 22.5564 11.6438 22.1181 11.2078L13.0538 2.09533C12.6155 1.65471 11.9067 1.65471 11.4731 2.09533L10.438 3.13596C9.99504 3.58127 10.0044 4.30783 10.4566 4.74377L16.0752 10.125H2.6746C2.05447 10.125 1.55556 10.6266 1.55556 11.25V12.75C1.55556 13.3735 2.05447 13.875 2.6746 13.875H16.0752L10.4566 19.2563C9.9997 19.6922 9.99038 20.4188 10.438 20.8641Z" />
  </svg>
);

export default ArrowRight;
