/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter v3.1.1
 */

const CintasToolsGray = () => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      d="M12.0271 11.5601C12.0271 11.5601 16.3688 4.57568 19.4024 1.01419C21.4498 4.14291 18.2995 8.42616 15.9563 11.612C15.0593 12.8315 14.2806 13.8903 13.9571 14.6619C14.038 16.0092 14.5085 18.0738 14.5085 18.0738C14.5085 18.0738 16.5322 15.9366 18.1617 16.0404C20.2238 16.1719 21.8545 17.8355 21.7804 19.9004C21.7052 21.9959 19.8755 23.3567 17.7826 23.4846C15.3995 23.6303 12.0271 17.7636 12.0271 17.7636C12.0271 17.7636 8.32654 23.4846 6.23723 23.4846C4.12439 23.4846 2.33818 21.8061 2.37728 19.6936C2.41569 17.6185 4.16177 16.0479 6.23723 16.0404C7.67021 16.0353 9.47683 17.7636 9.47683 17.7636C9.47683 17.7636 9.91261 16.0349 10.0283 14.9031C9.65816 13.8837 8.75179 12.656 7.75153 11.3012C5.47165 8.21319 2.70394 4.4644 4.68634 1.01419C6.58342 3.39634 12.0271 11.5601 12.0271 11.5601ZM17.9253 17.6166C19.0935 17.638 20.0321 18.6317 19.9725 19.7986C19.9159 20.9068 19.0347 21.7491 17.9253 21.7689C16.748 21.7899 15.7651 20.8062 15.8011 19.6293C15.836 18.4871 16.7827 17.5958 17.9253 17.6166ZM8.17842 19.7986C8.23799 18.6317 7.29945 17.638 6.13119 17.6166C4.9886 17.5958 4.04191 18.4871 4.00699 19.6293C3.97101 20.8062 4.95395 21.7899 6.13119 21.7689C7.24062 21.7491 8.12185 20.9068 8.17842 19.7986Z"
      fill="white"
    />
    <path
      d="M17.9291 23.8326C17.678 23.8326 17.3989 23.8046 17.1477 23.7488C16.171 23.5535 15.3338 23.0512 14.7198 22.2977L14.5524 22.0744C14.5245 22.0465 14.5245 22.0186 14.4966 21.9907L14.4687 21.9628C14.4687 21.9628 6.87797 10.7721 4.67332 7.53488C2.35704 4.13023 4.1989 0.837207 4.25471 0.697672L4.5896 0.111626L14.4408 14.6233L14.4129 14.7907C14.2454 15.6 14.3012 16.4372 14.5524 17.2186C14.8315 16.8279 15.1942 16.493 15.5849 16.2139C17.4826 14.9302 20.078 15.4326 21.3617 17.3023C22.6454 19.2 22.1431 21.7953 20.2733 23.0791C19.5756 23.5814 18.7663 23.8326 17.9291 23.8326ZM14.9152 21.1535L15.1384 21.5163C15.1384 21.5442 15.1663 21.5442 15.1663 21.5721C15.6687 22.2977 16.4222 22.8 17.2873 22.9674C18.1524 23.1349 19.0454 22.9395 19.771 22.4651C20.4966 21.9907 20.9989 21.2093 21.1663 20.3442C21.3338 19.4791 21.1384 18.586 20.664 17.8605C19.6315 16.3535 17.5663 15.9628 16.0594 16.9953C15.557 17.3581 15.1384 17.8326 14.8873 18.4186L14.5524 19.2279L14.1338 18.4465C13.5477 17.3581 13.3524 16.1023 13.5756 14.8744L4.70122 1.78604C4.33843 2.8186 3.89192 4.93953 5.37099 7.08837C7.32448 9.96279 13.6035 19.2279 14.9152 21.1535Z"
      fill="#75828B"
    />
    <path
      d="M17.9292 22.1302C17.1478 22.1302 16.3664 21.7674 15.8919 21.0698C15.1385 19.9535 15.4175 18.4186 16.5338 17.6651C17.064 17.3023 17.7338 17.1628 18.3757 17.2744C19.0175 17.386 19.5757 17.7767 19.9385 18.307C20.6919 19.4233 20.4129 20.9581 19.2966 21.7116C18.878 21.9907 18.4036 22.1302 17.9292 22.1302ZM17.9292 18C17.5943 18 17.2594 18.1116 16.9803 18.2791C16.6175 18.5302 16.3664 18.9209 16.2826 19.3674C16.1989 19.8139 16.2826 20.2605 16.5338 20.6233C17.064 21.4046 18.1245 21.6 18.878 21.0698C19.6594 20.5395 19.8547 19.4791 19.3245 18.7256C19.0733 18.3628 18.6826 18.1116 18.2361 18.0279C18.1245 18 18.0408 18 17.9292 18Z"
      fill="#75828B"
    />
    <path
      d="M14.3849 14.7907L13.6873 14.3163L14.5524 13.0326C16.2268 10.6046 17.7896 8.28837 18.6268 7.08837C20.078 4.96744 19.6315 2.84651 19.2687 1.78605L12.4035 11.9163L11.7059 11.4419L19.3803 0.139534L19.7152 0.725581C19.7989 0.865116 21.6128 4.15814 19.2966 7.56279C18.4873 8.76279 16.8966 11.107 15.2501 13.5349L14.3849 14.7907Z"
      fill="#75828B"
    />
    <path
      d="M6.04078 23.8326C5.20357 23.8326 4.42217 23.5814 3.7245 23.107C2.80357 22.493 2.18962 21.5442 1.99427 20.4558C1.79892 19.3674 2.02217 18.2512 2.63613 17.3581C3.25008 16.4372 4.19892 15.8233 5.28729 15.6279C6.37566 15.4326 7.49194 15.6558 8.38496 16.2698C8.77566 16.5488 9.13845 16.8837 9.41752 17.2744C9.66869 16.493 9.7245 15.6558 9.55706 14.8465L9.52915 14.6791L9.7245 14.3721L10.2268 14.707L10.3664 14.6791C10.3664 14.7349 10.3943 14.7628 10.3943 14.8186H10.4222V14.8465C10.6175 16.0744 10.4222 17.3302 9.86403 18.4186L9.44543 19.2L9.11055 18.3907C8.85938 17.8046 8.44078 17.3302 7.93845 16.9674C7.21287 16.4651 6.31985 16.2977 5.45473 16.4651C4.58962 16.6326 3.83613 17.1349 3.3338 17.8605C2.83148 18.586 2.66404 19.4791 2.83148 20.3442C2.99892 21.2093 3.50124 21.9628 4.22683 22.4651C4.95241 22.9674 5.84543 23.1349 6.71055 22.9674C7.57566 22.8 8.32915 22.2977 8.83148 21.5721C8.83148 21.5442 8.85938 21.5442 8.85938 21.5163L9.11055 21.1535L11.6501 17.3302L12.3478 17.8046L9.27799 22.3256C8.66403 23.0791 7.79892 23.5814 6.85008 23.7767C6.57101 23.8046 6.29194 23.8326 6.04078 23.8326Z"
      fill="#75828B"
    />
    <path
      d="M6.04081 22.1302C5.56639 22.1302 5.09197 21.9907 4.67337 21.7116C3.55709 20.9581 3.25011 19.4233 4.03151 18.307C4.3943 17.7767 4.95244 17.386 5.5943 17.2744C6.23616 17.1628 6.90593 17.2744 7.43616 17.6651C7.96639 18.0279 8.35709 18.586 8.46872 19.2279C8.58034 19.8698 8.46872 20.5116 8.07802 21.0698C7.6036 21.7674 6.8222 22.1302 6.04081 22.1302ZM6.06872 18C5.95709 18 5.84546 18 5.76174 18.0279C5.31523 18.1116 4.92453 18.3628 4.67337 18.7256C4.14313 19.507 4.33848 20.5395 5.11988 21.0698C5.90127 21.6 6.93383 21.4046 7.46406 20.6233C7.71523 20.2605 7.79895 19.786 7.71523 19.3674C7.63151 18.9209 7.38034 18.5302 7.01755 18.2791C6.71058 18.0837 6.4036 18 6.06872 18Z"
      fill="#75828B"
    />
    <path
      d="M11.9291 15.1256C12.2528 15.1256 12.5152 14.8632 12.5152 14.5395C12.5152 14.2159 12.2528 13.9535 11.9291 13.9535C11.6055 13.9535 11.3431 14.2159 11.3431 14.5395C11.3431 14.8632 11.6055 15.1256 11.9291 15.1256Z"
      fill="#75828B"
    />
  </svg>
);

export default CintasToolsGray;
