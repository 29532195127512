/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter v3.1.1
 */

const AngleLeft = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path d="M7.48594 11.2031L13.8609 4.82812C14.3016 4.3875 15.0141 4.3875 15.45 4.82812L16.5094 5.8875C16.95 6.32812 16.95 7.04062 16.5094 7.47656L11.9953 12L16.514 16.5187C16.9547 16.9594 16.9547 17.6719 16.514 18.1078L15.4547 19.1719C15.014 19.6125 14.3016 19.6125 13.8656 19.1719L7.49062 12.7969C7.04531 12.3562 7.04531 11.6437 7.48594 11.2031Z" />
  </svg>
);

export default AngleLeft;
