/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter v3.1.1
 */

const CleverFirstAidLogoPadded = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path d="M2.95891 9.0411C2.95891 8.04244 3.76848 7.23288 4.76713 7.23288H6.73973V8.21918H4.76713C4.31319 8.21918 3.94521 8.58716 3.94521 9.0411V14.9589C3.94521 15.4128 4.31319 15.7808 4.76713 15.7808H10.0274C10.1182 15.7808 10.1918 15.7072 10.1918 15.6164V13.9726C10.1918 13.8818 10.1182 13.8082 10.0274 13.8082H8.38357C8.29278 13.8082 8.21918 13.8818 8.21918 13.9726V15.2877H7.23288V13.9726C7.23288 13.3371 7.74806 12.8219 8.38357 12.8219H10.0274C10.6629 12.8219 11.1781 13.3371 11.1781 13.9726V15.6164C11.1781 16.2519 10.6629 16.7671 10.0274 16.7671H4.76713C3.76848 16.7671 2.95891 15.9576 2.95891 14.9589V9.0411Z" />
    <path d="M21.0411 14.9589C21.0411 15.9576 20.2315 16.7671 19.2329 16.7671H17.2603V15.7808H19.2329C19.6868 15.7808 20.0548 15.4128 20.0548 14.9589L20.0548 9.0411C20.0548 8.58716 19.6868 8.21918 19.2329 8.21918L13.9726 8.21918C13.8818 8.21918 13.8082 8.29278 13.8082 8.38356L13.8082 10.0274C13.8082 10.1182 13.8818 10.1918 13.9726 10.1918L15.6164 10.1918C15.7072 10.1918 15.7808 10.1182 15.7808 10.0274L15.7808 8.71233H16.7671L16.7671 10.0274C16.7671 10.6629 16.2519 11.1781 15.6164 11.1781H13.9726C13.3371 11.1781 12.8219 10.6629 12.8219 10.0274V8.38356C12.8219 7.74806 13.3371 7.23288 13.9726 7.23288L19.2329 7.23288C20.2315 7.23288 21.0411 8.04244 21.0411 9.0411L21.0411 14.9589Z" />
    <path d="M9.0411 21.0411C8.04245 21.0411 7.23288 20.2315 7.23288 19.2329L7.23288 17.2603H8.21918L8.21918 19.2329C8.21918 19.6868 8.58717 20.0548 9.0411 20.0548H14.9589C15.4128 20.0548 15.7808 19.6868 15.7808 19.2329V13.9726C15.7808 13.8818 15.7072 13.8082 15.6164 13.8082H13.9726C13.8818 13.8082 13.8082 13.8818 13.8082 13.9726V15.6164C13.8082 15.7072 13.8818 15.7808 13.9726 15.7808H15.2877V16.7671H13.9726C13.3371 16.7671 12.8219 16.2519 12.8219 15.6164V13.9726C12.8219 13.3371 13.3371 12.8219 13.9726 12.8219H15.6164C16.2519 12.8219 16.7671 13.3371 16.7671 13.9726V19.2329C16.7671 20.2315 15.9576 21.0411 14.9589 21.0411H9.0411Z" />
    <path d="M14.9589 2.9589C15.9576 2.9589 16.7671 3.76847 16.7671 4.76712V6.73973L15.7808 6.73973V4.76712C15.7808 4.31319 15.4128 3.94521 14.9589 3.94521L9.0411 3.94521C8.58717 3.94521 8.21918 4.31319 8.21918 4.76712L8.21918 10.0274C8.21918 10.1182 8.29278 10.1918 8.38357 10.1918H10.0274C10.1182 10.1918 10.1918 10.1182 10.1918 10.0274V8.38356C10.1918 8.29278 10.1182 8.21918 10.0274 8.21918H8.71233V7.23288L10.0274 7.23288C10.6629 7.23288 11.1781 7.74806 11.1781 8.38356V10.0274C11.1781 10.6629 10.6629 11.1781 10.0274 11.1781H8.38357C7.74806 11.1781 7.23288 10.6629 7.23288 10.0274L7.23288 4.76712C7.23288 3.76847 8.04245 2.9589 9.0411 2.9589L14.9589 2.9589Z" />
  </svg>
);

export default CleverFirstAidLogoPadded;
