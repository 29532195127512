/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter v3.1.1
 */

const CloudOffline = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path d="M20.2463 21.7161C20.6563 22.1261 21.3211 22.1261 21.7312 21.7161C22.1412 21.306 22.1412 20.6412 21.7312 20.2312L4.78265 3.28267C4.3726 2.87262 3.70777 2.87262 3.29772 3.28267C2.88767 3.69272 2.88767 4.35754 3.29772 4.76759L20.2463 21.7161Z" />
    <path d="M12.5255 4.44972C13.377 4.81975 14.1795 5.35259 14.7753 6.03154C15.2445 5.84557 15.7564 5.74288 16.29 5.74288C18.5625 5.74288 20.4075 7.58471 20.4075 9.85716C20.4075 10.042 20.3951 10.2241 20.3714 10.4027C22.278 11.1686 23.625 13.0344 23.625 15.2143C23.625 15.7998 23.5736 16.3233 23.3969 16.8279C23.2179 17.3394 22.9333 17.7667 22.5613 18.1958C22.2357 18.5714 21.6673 18.6119 21.2917 18.2863C20.9161 17.9607 20.8756 17.3923 21.2012 17.0167C21.4808 16.6942 21.618 16.4618 21.698 16.2333C21.7803 15.998 21.825 15.7013 21.825 15.2143C21.825 13.5786 20.6606 12.2099 19.1149 11.8978L18.0715 11.6872L18.4527 10.6933C18.5519 10.4347 18.6075 10.1521 18.6075 9.85716C18.6075 8.58051 17.5701 7.54288 16.29 7.54288C15.8141 7.54288 15.3709 7.68741 15.0048 7.93285L14.2056 8.46862L13.7243 7.63547C13.3858 7.04941 12.7061 6.49083 11.8081 6.10056C10.919 5.7142 9.90723 5.53401 9.00364 5.62089C8.50887 5.66847 8.06921 5.30594 8.02163 4.81117C7.97406 4.31639 8.33659 3.87673 8.83136 3.82916C10.0728 3.70979 11.3898 3.95617 12.5255 4.44972Z" />
    <path d="M5.52046 8.78351C5.68196 8.31342 5.4318 7.80142 4.96171 7.63992C4.49163 7.47842 3.97962 7.72858 3.81812 8.19867C3.70943 8.51506 3.64537 8.77261 3.61553 9.05595C3.59915 9.21144 3.59417 9.37029 3.59278 9.53343C1.68958 10.4618 0.375 12.4154 0.375 14.6786C0.375 17.8383 2.94055 20.4 6.10125 20.4H15.9C16.3971 20.4 16.8 19.9971 16.8 19.5C16.8 19.0029 16.3971 18.6 15.9 18.6H6.10125C3.93297 18.6 2.175 16.8425 2.175 14.6786C2.175 12.9703 3.26937 11.5145 4.7976 10.9775L5.42315 10.7576L5.39859 10.095C5.39513 10.0018 5.3925 9.9281 5.3925 9.85716C5.3925 9.83004 5.39249 9.80418 5.39248 9.77946V9.77845C5.39238 9.50267 5.39233 9.37071 5.40563 9.24449C5.4173 9.13365 5.44165 9.01294 5.52046 8.78351Z" />
  </svg>
);

export default CloudOffline;
