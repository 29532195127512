/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter v3.1.1
 */

const AngleRight = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path d="M16.5141 11.2031L10.1391 4.82812C9.69845 4.38749 8.98595 4.38749 8.55001 4.82812L7.49064 5.88749C7.05001 6.32812 7.05001 7.04062 7.49064 7.47656L12.0047 12L7.48595 16.5187C7.04533 16.9594 7.04533 17.6719 7.48595 18.1078L8.54532 19.1719C8.98595 19.6125 9.69845 19.6125 10.1344 19.1719L16.5094 12.7969C16.9547 12.3562 16.9547 11.6437 16.5141 11.2031Z" />
  </svg>
);

export default AngleRight;
