/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter v3.1.1
 */

const Phone = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path d="M23.1282 1.15361L18.2533 0.0286313C17.7236 -0.0932411 17.1798 0.183316 16.9642 0.68018L14.7143 5.93007C14.5174 6.38943 14.6486 6.92849 15.0377 7.24254L17.8783 9.56749C16.1908 13.1627 13.2424 16.1533 9.57217 17.8736L7.24722 15.033C6.92848 14.644 6.39411 14.5127 5.93475 14.7096L0.68485 16.9595C0.183297 17.1798 -0.0932599 17.7236 0.0286127 18.2532L1.15359 23.1281C1.27078 23.6344 1.72077 24 2.25044 24C14.2549 24 24 14.2736 24 2.25046C24 1.72547 23.6391 1.27079 23.1282 1.15361Z" />
  </svg>
);

export default Phone;
