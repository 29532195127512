/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter v3.1.1
 */

const Filter = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path d="M21.75 6.12714H20.1094C19.4548 4.61106 17.9463 3.54999 16.19 3.54999C14.4337 3.54999 12.9252 4.61106 12.2706 6.12714H2.25C1.83577 6.12714 1.5 6.4629 1.5 6.87714V8.75214C1.5 9.16637 1.83577 9.50214 2.25 9.50214H12.2684C12.9219 11.0209 14.4317 12.0843 16.19 12.0843C17.9482 12.0843 19.4581 11.0209 20.1116 9.50214H21.75C22.1642 9.50214 22.5 9.16637 22.5 8.75214V6.87714C22.5 6.4629 22.1642 6.12714 21.75 6.12714ZM16.19 9.50714C17.1234 9.50714 17.88 8.7505 17.88 7.81714C17.88 6.88377 17.1234 6.12714 16.19 6.12714C15.2566 6.12714 14.5 6.88377 14.5 7.81714C14.5 8.7505 15.2566 9.50714 16.19 9.50714Z" />
    <path d="M2.25 14.4975H3.8906C4.54519 12.9814 6.05369 11.9203 7.81 11.9203C9.56632 11.9203 11.0748 12.9814 11.7294 14.4975H21.75C22.1642 14.4975 22.5 14.8333 22.5 15.2475V17.1225C22.5 17.5367 22.1642 17.8725 21.75 17.8725H11.7316C11.0781 19.3912 9.56825 20.4546 7.81 20.4546C6.05175 20.4546 4.54188 19.3912 3.88844 17.8725H2.25C1.83577 17.8725 1.5 17.5367 1.5 17.1225V15.2475C1.5 14.8333 1.83577 14.4975 2.25 14.4975ZM7.81 17.8775C6.87664 17.8775 6.12 17.1209 6.12 16.1875C6.12 15.2541 6.87664 14.4975 7.81 14.4975C8.74336 14.4975 9.5 15.2541 9.5 16.1875C9.5 17.1209 8.74336 17.8775 7.81 17.8775Z" />
  </svg>
);

export default Filter;
