/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter v3.1.1
 */

const Copy = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path d="M19.5 15.75H10.5C10.0875 15.75 9.75 15.4125 9.75 15V3C9.75 2.5875 10.0875 2.25 10.5 2.25H17.0672L20.25 5.43281V15C20.25 15.4125 19.9125 15.75 19.5 15.75ZM10.5 18H19.5C21.1547 18 22.5 16.6547 22.5 15V5.43281C22.5 4.8375 22.2609 4.26562 21.8391 3.84375L18.6609 0.660938C18.2391 0.239063 17.6672 0 17.0719 0H10.5C8.84531 0 7.5 1.34531 7.5 3V15C7.5 16.6547 8.84531 18 10.5 18ZM4.5 6C2.84531 6 1.5 7.34531 1.5 9V21C1.5 22.6547 2.84531 24 4.5 24H13.5C15.1547 24 16.5 22.6547 16.5 21V19.5H14.25V21C14.25 21.4125 13.9125 21.75 13.5 21.75H4.5C4.0875 21.75 3.75 21.4125 3.75 21V9C3.75 8.5875 4.0875 8.25 4.5 8.25H6V6H4.5Z" />
  </svg>
);

export default Copy;
