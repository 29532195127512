/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter v3.1.1
 */

const Info = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM10.928 10V18H13.68V10H10.928ZM11.2 8.624C11.488 8.88 11.856 9.008 12.304 9.008C12.752 9.008 13.12 8.88 13.408 8.624C13.696 8.368 13.84 8.03733 13.84 7.632C13.84 7.22667 13.696 6.896 13.408 6.64C13.12 6.384 12.752 6.256 12.304 6.256C11.856 6.256 11.488 6.384 11.2 6.64C10.912 6.896 10.768 7.22667 10.768 7.632C10.768 8.03733 10.912 8.368 11.2 8.624Z" />
  </svg>
);

export default Info;
