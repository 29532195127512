/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter v3.1.1
 */

const Spinner = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <g>
      <path d="M14.25 2.25C14.25 3.49266 13.2427 4.5 12 4.5C10.7573 4.5 9.75 3.49266 9.75 2.25C9.75 1.00734 10.7573 0 12 0C13.2427 0 14.25 1.00734 14.25 2.25ZM12 19.5C10.7573 19.5 9.75 20.5073 9.75 21.75C9.75 22.9927 10.7573 24 12 24C13.2427 24 14.25 22.9927 14.25 21.75C14.25 20.5073 13.2427 19.5 12 19.5ZM21.75 9.75C20.5073 9.75 19.5 10.7573 19.5 12C19.5 13.2427 20.5073 14.25 21.75 14.25C22.9927 14.25 24 13.2427 24 12C24 10.7573 22.9927 9.75 21.75 9.75ZM4.5 12C4.5 10.7573 3.49266 9.75 2.25 9.75C1.00734 9.75 0 10.7573 0 12C0 13.2427 1.00734 14.25 2.25 14.25C3.49266 14.25 4.5 13.2427 4.5 12ZM5.10572 16.6443C3.86306 16.6443 2.85572 17.6516 2.85572 18.8943C2.85572 20.1369 3.86306 21.1443 5.10572 21.1443C6.34837 21.1443 7.35572 20.1369 7.35572 18.8943C7.35572 17.6517 6.34833 16.6443 5.10572 16.6443ZM18.8943 16.6443C17.6516 16.6443 16.6443 17.6516 16.6443 18.8943C16.6443 20.1369 17.6516 21.1443 18.8943 21.1443C20.1369 21.1443 21.1443 20.1369 21.1443 18.8943C21.1443 17.6517 20.1369 16.6443 18.8943 16.6443ZM5.10572 2.85572C3.86306 2.85572 2.85572 3.86306 2.85572 5.10572C2.85572 6.34837 3.86306 7.35572 5.10572 7.35572C6.34837 7.35572 7.35572 6.34837 7.35572 5.10572C7.35572 3.86306 6.34833 2.85572 5.10572 2.85572Z" />
    </g>
    <defs>
      <clipPath>
        <rect width="24" height="24" />
      </clipPath>
    </defs>
  </svg>
);

export default Spinner;
