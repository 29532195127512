/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter v3.1.1
 */

const ArrowAltLeft = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path d="M7.78402 13.875H21.9375C22.2481 13.875 22.5 13.6231 22.5 13.3125V10.6875C22.5 10.3769 22.2481 10.125 21.9375 10.125H7.78402V7.96598C7.78402 6.9637 6.57225 6.46177 5.8635 7.17047L1.82949 11.2045C1.39013 11.6438 1.39013 12.3562 1.82949 12.7955L5.8635 16.8295C6.5722 17.5382 7.78402 17.0363 7.78402 16.034V13.875Z" />
  </svg>
);

export default ArrowAltLeft;
