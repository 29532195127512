/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter v3.1.1
 */

const Mobile = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path d="M17.25 0H6.75C5.50781 0 4.5 1.00781 4.5 2.25V21.75C4.5 22.9922 5.50781 24 6.75 24H17.25C18.4922 24 19.5 22.9922 19.5 21.75V2.25C19.5 1.00781 18.4922 0 17.25 0ZM12 22.5C11.1703 22.5 10.5 21.8297 10.5 21C10.5 20.1703 11.1703 19.5 12 19.5C12.8297 19.5 13.5 20.1703 13.5 21C13.5 21.8297 12.8297 22.5 12 22.5Z" />
  </svg>
);

export default Mobile;
