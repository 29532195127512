/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter v3.1.1
 */

const ToggleOff = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path d="M16 4H8C3.58171 4 0 7.58171 0 12C0 16.4183 3.58171 20 8 20H16C20.4183 20 24 16.4183 24 12C24 7.58171 20.4183 4 16 4ZM2.66667 12C2.66667 9.05246 5.05204 6.66667 8 6.66667C10.9475 6.66667 13.3333 9.05204 13.3333 12C13.3333 14.9475 10.948 17.3333 8 17.3333C5.05246 17.3333 2.66667 14.948 2.66667 12ZM16 17.3333H13.9623C16.6797 14.2976 16.6805 9.70333 13.9623 6.66667H16C18.9475 6.66667 21.3333 9.05204 21.3333 12C21.3333 14.9475 18.948 17.3333 16 17.3333Z" />
  </svg>
);

export default ToggleOff;
