/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter v3.1.1
 */

const Aed = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path d="M11.4107 22.0454C11.5714 22.2061 11.7679 22.2864 12 22.2864C12.2321 22.2864 12.4286 22.2061 12.5893 22.0454L20.933 14.0096C22.9777 11.965 24 9.95607 24 7.98285C24 6.01857 23.433 4.48285 22.2991 3.37571C21.1652 2.26857 19.5982 1.715 17.5982 1.715C17.0446 1.715 16.4799 1.81098 15.904 2.00294C15.3281 2.19491 14.7924 2.45384 14.2969 2.77973C13.8013 3.10562 13.375 3.41142 13.0179 3.69714C12.6607 3.98285 12.3214 4.28642 12 4.60785C11.6786 4.28642 11.3393 3.98285 10.9821 3.69714C10.625 3.41142 10.1987 3.10562 9.70312 2.77973C9.20759 2.45384 8.67188 2.19491 8.09598 2.00294C7.52009 1.81098 6.95536 1.715 6.40179 1.715C4.40179 1.715 2.83482 2.26857 1.70089 3.37571C0.566964 4.48285 0 6.01857 0 7.98285C0 8.58107 0.104911 9.19714 0.314732 9.83107C0.524554 10.465 0.763393 11.0052 1.03125 11.4516C1.29911 11.898 1.60268 12.3333 1.94196 12.7574C2.28125 13.1815 2.52902 13.4739 2.68527 13.6346C2.84152 13.7954 2.96429 13.9114 3.05357 13.9829L11.4107 22.0454ZM15.9141 9.13687C16.0078 9.24104 16.0261 9.35562 15.9688 9.48062L11.75 18.5197C11.6823 18.6499 11.5729 18.715 11.4219 18.715C11.401 18.715 11.3646 18.7098 11.3125 18.6994C11.224 18.6733 11.1576 18.6238 11.1133 18.5509C11.069 18.478 11.0573 18.3999 11.0781 18.3166L12.6172 12.0041L9.44532 12.7931C9.42449 12.7983 9.39324 12.8009 9.35157 12.8009C9.25782 12.8009 9.17709 12.7723 9.10938 12.715C9.01563 12.6369 8.98178 12.5353 9.00782 12.4103L10.5781 5.965C10.599 5.89208 10.6406 5.83218 10.7031 5.78531C10.7656 5.73843 10.8385 5.715 10.9219 5.715H13.4844C13.5833 5.715 13.6667 5.74755 13.7344 5.81265C13.8021 5.87776 13.8359 5.95458 13.8359 6.04312C13.8359 6.08479 13.8229 6.13166 13.7969 6.18375L12.4609 9.80093L15.5547 9.03531C15.5964 9.02489 15.6276 9.01968 15.6484 9.01968C15.7474 9.01968 15.8359 9.05875 15.9141 9.13687Z" />
  </svg>
);

export default Aed;
