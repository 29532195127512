/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter v3.1.1
 */

const LevelUpOut = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <g>
      <path d="M18.3906 6.30213L23.6408 11.1755C24.1195 11.6201 24.1202 12.3791 23.6408 12.8243L18.3906 17.6981C17.6734 18.3641 16.5001 17.8564 16.5001 16.8738V13.8749L3.75011 13.8749L3.75011 17.8113C3.75011 17.8852 3.73557 17.9583 3.70729 18.0266C3.67902 18.0948 3.63758 18.1568 3.58534 18.209L0.96034 20.834C0.606012 21.1884 0.00010679 20.9374 0.000106812 20.4363L0.000107213 11.2499C0.00010724 10.6286 0.503779 10.1249 1.12511 10.1249L16.5001 10.1249V7.12652C16.5001 6.14787 17.6705 5.63351 18.3906 6.30213Z" />
    </g>
    <defs>
      <clipPath>
        <rect width="24" height="24" transform="translate(24) rotate(90)" />
      </clipPath>
    </defs>
  </svg>
);

export default LevelUpOut;
