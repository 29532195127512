/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter v3.1.1
 */

const CaretSquareDown = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path d="M7.36406 9.75H16.6359C17.1375 9.75 17.3906 10.3594 17.0344 10.7109L12.3984 15.3187C12.1781 15.5391 11.8266 15.5391 11.6063 15.3187L6.97031 10.7109C6.60938 10.3594 6.8625 9.75 7.36406 9.75ZM22.5 3.75V20.25C22.5 21.4922 21.4922 22.5 20.25 22.5H3.75C2.50781 22.5 1.5 21.4922 1.5 20.25V3.75C1.5 2.50781 2.50781 1.5 3.75 1.5H20.25C21.4922 1.5 22.5 2.50781 22.5 3.75ZM20.25 19.9688V4.03125C20.25 3.87656 20.1234 3.75 19.9688 3.75H4.03125C3.87656 3.75 3.75 3.87656 3.75 4.03125V19.9688C3.75 20.1234 3.87656 20.25 4.03125 20.25H19.9688C20.1234 20.25 20.25 20.1234 20.25 19.9688Z" />
  </svg>
);

export default CaretSquareDown;
