/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter v3.1.1
 */

const CleverFirstAidLogo = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path d="M1 8.4C1 7.18497 1.98497 6.2 3.2 6.2H5.6V7.4H3.2C2.64772 7.4 2.2 7.84772 2.2 8.4V15.6C2.2 16.1523 2.64772 16.6 3.2 16.6H9.6C9.71046 16.6 9.8 16.5105 9.8 16.4V14.4C9.8 14.2895 9.71046 14.2 9.6 14.2H7.6C7.48954 14.2 7.4 14.2895 7.4 14.4V16H6.2V14.4C6.2 13.6268 6.8268 13 7.6 13H9.6C10.3732 13 11 13.6268 11 14.4V16.4C11 17.1732 10.3732 17.8 9.6 17.8H3.2C1.98497 17.8 1 16.815 1 15.6V8.4Z" />
    <path d="M23 15.6C23 16.815 22.015 17.8 20.8 17.8H18.4V16.6H20.8C21.3523 16.6 21.8 16.1523 21.8 15.6L21.8 8.4C21.8 7.84771 21.3523 7.4 20.8 7.4L14.4 7.4C14.2895 7.4 14.2 7.48954 14.2 7.6L14.2 9.6C14.2 9.71046 14.2895 9.8 14.4 9.8L16.4 9.8C16.5105 9.8 16.6 9.71046 16.6 9.6L16.6 8H17.8L17.8 9.6C17.8 10.3732 17.1732 11 16.4 11L14.4 11C13.6268 11 13 10.3732 13 9.6V7.6C13 6.8268 13.6268 6.2 14.4 6.2H20.8C22.015 6.2 23 7.18497 23 8.4L23 15.6Z" />
    <path d="M8.4 23C7.18497 23 6.2 22.015 6.2 20.8L6.2 18.4H7.4L7.4 20.8C7.4 21.3523 7.84772 21.8 8.4 21.8H15.6C16.1523 21.8 16.6 21.3523 16.6 20.8V14.4C16.6 14.2895 16.5105 14.2 16.4 14.2H14.4C14.2895 14.2 14.2 14.2895 14.2 14.4V16.4C14.2 16.5105 14.2895 16.6 14.4 16.6H16V17.8H14.4C13.6268 17.8 13 17.1732 13 16.4V14.4C13 13.6268 13.6268 13 14.4 13H16.4C17.1732 13 17.8 13.6268 17.8 14.4V20.8C17.8 22.015 16.815 23 15.6 23H8.4Z" />
    <path d="M15.6 1C16.815 1 17.8 1.98497 17.8 3.2V5.6H16.6V3.2C16.6 2.64772 16.1523 2.2 15.6 2.2L8.4 2.2C7.84771 2.2 7.4 2.64772 7.4 3.2L7.4 9.6C7.4 9.71046 7.48954 9.8 7.6 9.8H9.6C9.71046 9.8 9.8 9.71046 9.8 9.6V7.6C9.8 7.48954 9.71046 7.4 9.6 7.4H8V6.2L9.6 6.2C10.3732 6.2 11 6.8268 11 7.6V9.6C11 10.3732 10.3732 11 9.6 11H7.6C6.8268 11 6.2 10.3732 6.2 9.6L6.2 3.2C6.2 1.98497 7.18497 1 8.4 1L15.6 1Z" />
  </svg>
);

export default CleverFirstAidLogo;
