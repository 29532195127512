/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter v3.1.1
 */

const CloudDownload = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path d="M20.16 10.8975C20.3137 10.4962 20.4 10.0575 20.4 9.59998C20.4 7.61248 18.7875 5.99998 16.8 5.99998C16.0613 5.99998 15.3713 6.22498 14.8013 6.60748C13.7625 4.80748 11.8238 3.59998 9.6 3.59998C6.285 3.59998 3.6 6.28498 3.6 9.59998C3.6 9.70123 3.60375 9.80248 3.6075 9.90373C1.5075 10.6425 0 12.645 0 15C0 17.9812 2.41875 20.4 5.4 20.4H19.2C21.8513 20.4 24 18.2512 24 15.6C24 13.2787 22.35 11.34 20.16 10.8975ZM15.1763 14.2237L11.2238 18.1762C10.9913 18.4087 10.6088 18.4087 10.3763 18.1762L6.42375 14.2237C6.045 13.845 6.315 13.2 6.8475 13.2H9.3V8.99998C9.3 8.66998 9.57 8.39998 9.9 8.39998H11.7C12.03 8.39998 12.3 8.66998 12.3 8.99998V13.2H14.7525C15.285 13.2 15.555 13.845 15.1763 14.2237Z" />
  </svg>
);

export default CloudDownload;
