/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter v3.1.1
 */

const LevelUpIn = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <g>
      <path d="M0.802191 18.3906L5.6756 23.6408C6.12021 24.1196 6.87916 24.1203 7.32438 23.6408L12.1982 18.3906C12.8641 17.6734 12.3565 16.5001 11.3738 16.5001H8.37499V11.7501H20.3114C20.3852 11.7501 20.4584 11.7356 20.5266 11.7073C20.5949 11.679 20.6569 11.6376 20.7091 11.5854L23.3341 8.96036C23.6885 8.60603 23.4375 8.00012 22.9364 8.00012H5.74999C5.12866 8.00012 4.62499 8.50379 4.62499 9.12512V16.5001H1.62658C0.647925 16.5001 0.133566 17.6705 0.802191 18.3906Z" />
    </g>
    <defs>
      <clipPath>
        <rect width="24" height="24" transform="matrix(1 0 0 -1 0 24)" />
      </clipPath>
    </defs>
  </svg>
);

export default LevelUpIn;
