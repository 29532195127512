/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter v3.1.1
 */

const Heart = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <g>
      <path d="M21.6704 2.93437C19.1016 0.745312 15.2813 1.13906 12.9235 3.57187L12.0001 4.52344L11.0766 3.57187C8.72349 1.13906 4.89849 0.745312 2.32974 2.93437C-0.614006 5.44687 -0.768694 9.95625 1.86568 12.6797L10.936 22.0453C11.5219 22.65 12.4735 22.65 13.0594 22.0453L22.1297 12.6797C24.7688 9.95625 24.6141 5.44687 21.6704 2.93437Z" />
    </g>
    <defs>
      <clipPath>
        <rect width="24" height="24" />
      </clipPath>
    </defs>
  </svg>
);

export default Heart;
