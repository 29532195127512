/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter v3.1.1
 */

const CheckSquare = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <g>
      <path d="M10.7031 18.2969L20.2969 8.70312C20.4948 8.50521 20.5938 8.27083 20.5938 8C20.5938 7.72917 20.4948 7.49479 20.2969 7.29688L18.7031 5.70312C18.5052 5.50521 18.2708 5.40625 18 5.40625C17.7292 5.40625 17.4948 5.50521 17.2969 5.70312L10 13L6.70312 9.70312C6.50521 9.50521 6.27083 9.40625 6 9.40625C5.72917 9.40625 5.49479 9.50521 5.29688 9.70312L3.70312 11.2969C3.50521 11.4948 3.40625 11.7292 3.40625 12C3.40625 12.2708 3.50521 12.5052 3.70312 12.7031L9.29688 18.2969C9.49479 18.4948 9.72917 18.5938 10 18.5938C10.2708 18.5938 10.5052 18.4948 10.7031 18.2969ZM24 4.5V19.5C24 20.7396 23.5599 21.7995 22.6797 22.6797C21.7995 23.5599 20.7396 24 19.5 24H4.5C3.26042 24 2.20052 23.5599 1.32031 22.6797C0.440104 21.7995 0 20.7396 0 19.5V4.5C0 3.26042 0.440104 2.20052 1.32031 1.32031C2.20052 0.440104 3.26042 0 4.5 0H19.5C20.7396 0 21.7995 0.440104 22.6797 1.32031C23.5599 2.20052 24 3.26042 24 4.5Z" />
    </g>
    <defs>
      <clipPath>
        <rect width="24" height="24" />
      </clipPath>
    </defs>
  </svg>
);

export default CheckSquare;
