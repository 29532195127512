/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter v3.1.1
 */

const LocationArrow = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <g>
      <path d="M20.8373 0.16504L1.34761 9.16035C-0.900979 10.2099 -0.151448 13.508 2.24715 13.508H10.4929V21.7538C10.4929 24.1524 13.7911 24.9024 14.8406 22.6533L23.8359 3.16363C24.5854 1.3641 22.6364 -0.58496 20.8373 0.16504Z" />
    </g>
    <defs>
      <clipPath>
        <rect width="24" height="24" />
      </clipPath>
    </defs>
  </svg>
);

export default LocationArrow;
