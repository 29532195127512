/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter v3.1.1
 */

const UserFriends = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path d="M7.2 12C9.52125 12 11.4 10.1213 11.4 7.80001C11.4 5.47876 9.52125 3.60001 7.2 3.60001C4.87875 3.60001 3 5.47876 3 7.80001C3 10.1213 4.87875 12 7.2 12ZM10.08 13.2H9.76875C8.98875 13.575 8.1225 13.8 7.2 13.8C6.2775 13.8 5.415 13.575 4.63125 13.2H4.32C1.935 13.2 0 15.135 0 17.52V18.6C0 19.5938 0.80625 20.4 1.8 20.4H12.6C13.5938 20.4 14.4 19.5938 14.4 18.6V17.52C14.4 15.135 12.465 13.2 10.08 13.2ZM18 12C19.9875 12 21.6 10.3875 21.6 8.40001C21.6 6.41251 19.9875 4.80001 18 4.80001C16.0125 4.80001 14.4 6.41251 14.4 8.40001C14.4 10.3875 16.0125 12 18 12ZM19.8 13.2H19.6575C19.1363 13.38 18.585 13.5 18 13.5C17.415 13.5 16.8638 13.38 16.3425 13.2H16.2C15.435 13.2 14.73 13.4213 14.1112 13.7775C15.0262 14.7638 15.6 16.0725 15.6 17.52V18.96C15.6 19.0425 15.5813 19.1213 15.5775 19.2H22.2C23.1938 19.2 24 18.3938 24 17.4C24 15.0788 22.1213 13.2 19.8 13.2Z" />
  </svg>
);

export default UserFriends;
